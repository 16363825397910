import { useDispatch } from "react-redux";
import { resetGameOver } from "../slices/gameOverSlice";
import { closeModal } from "../slices/modalSlice";
import { initiallySetPlayerTurn } from "../slices/playerSlice";
import { resetTiles5Slice } from "../slices/tiles5Slice";
import { resetTiles7Slice } from "../slices/tiles7Slice";
import { resetTilesState } from "../slices/tilesSlice";
import { resetTilesEasyMode } from "../slices/tilesSliceEasy";
import { resetTilesHardMode } from "../slices/tilesSliceHard";
import { resetTilesMediumMode } from "../slices/tilesSliceMedium";
import { removePointsSaved, resetPoints } from "../slices/pointsSlice";

/* 

  RESET STATES


*/

export function reset(dispatch) {
  dispatch(resetTilesState());
  dispatch(initiallySetPlayerTurn());
  dispatch(resetTilesEasyMode());
  dispatch(resetTilesMediumMode());
  dispatch(resetTilesHardMode());
  dispatch(resetGameOver());
  dispatch(resetTiles5Slice());
  dispatch(resetTiles7Slice());
  dispatch(resetPoints());
  dispatch(closeModal());
}

export function reset2(dispatch){
  dispatch(resetTilesState());
  dispatch(resetTilesEasyMode());
  dispatch(resetTilesMediumMode());
  dispatch(resetTilesHardMode());
  dispatch(resetGameOver());
  dispatch(resetTiles5Slice());
  dispatch(resetTiles7Slice());
  dispatch(removePointsSaved());
  dispatch(closeModal());
}

/* 

  MATRIX 5 GAME LOGIC 


*/

const winningCombinationsForMatrix5 = [
  [0, 1, 2, 3],
  [1, 2, 3, 4],
  [5, 6, 7, 8],
  [6, 7, 8, 9],
  [10, 11, 12, 13],
  [11, 12, 13, 14],
  [15, 16, 17, 18],
  [16, 17, 18, 19],
  [20, 21, 22, 23],
  [21, 22, 23, 24],
  [0, 5, 10, 15],
  [5, 10, 15, 20],
  [6, 11, 16, 21],
  [1, 6, 11, 16],
  [7, 12, 17, 22],
  [8, 13, 18, 23],
  [9, 14, 19, 24],
  [4, 9, 14, 19],
  [3, 8, 13, 18],
  [2, 7, 12, 17],
  [0, 6, 12, 18],
  [6, 12, 18, 24],
  [8, 12, 16, 20],
  [4, 8, 12, 16],
  [1, 7, 13, 19],
  [5, 11, 17, 23],
  [3, 7, 11, 15],
  [9, 13, 17, 21],
];

export const calculateWinnerMatrix5 = (board) => {
  for (let combination of winningCombinationsForMatrix5) {
    const [a, b, c, d] = combination;
    if (
      board[a] &&
      board[b] &&
      board[c] &&
      board[d] &&
      board[a] == board[b] &&
      board[a] == board[c] &&
      board[a] == board[d]
    ) {
      console.log(board[b], "winner");
      return board[b];
    }
  }

  return null;
};

function doesObjectExistAtIndexWithValue(array, indexToCheck, valueToCheck) {
  const foundObject = array[indexToCheck] !== "";
  return foundObject && array[indexToCheck] === valueToCheck;
}

export const getSmartComputerMoveMatrix5 = (board) => {
  console.log("if exist");

  if (
    doesObjectExistAtIndexWithValue(board, 2, "X") &&
    doesObjectExistAtIndexWithValue(board, 3, "X") &&
    !doesObjectExistAtIndexWithValue(board, 1, "O") &&
    !doesObjectExistAtIndexWithValue(board, 1, "X")
  ) {
    return 1;
  }
  if (
    doesObjectExistAtIndexWithValue(board, 1, "X") &&
    doesObjectExistAtIndexWithValue(board, 2, "X") &&
    !doesObjectExistAtIndexWithValue(board, 3, "O") &&
    !doesObjectExistAtIndexWithValue(board, 3, "X")
  ) {
    return 3;
  }
  if (
    doesObjectExistAtIndexWithValue(board, 6, "X") &&
    doesObjectExistAtIndexWithValue(board, 7, "X") &&
    !doesObjectExistAtIndexWithValue(board, 8, "O") &&
    !doesObjectExistAtIndexWithValue(board, 8, "X")
  ) {
    return 8;
  }
  if (
    doesObjectExistAtIndexWithValue(board, 8, "X") &&
    doesObjectExistAtIndexWithValue(board, 7, "X") &&
    !doesObjectExistAtIndexWithValue(board, 6, "O") &&
    !doesObjectExistAtIndexWithValue(board, 6, "X")
  ) {
    return 6;
  }
  if (
    doesObjectExistAtIndexWithValue(board, 11, "X") &&
    doesObjectExistAtIndexWithValue(board, 12, "X") &&
    !doesObjectExistAtIndexWithValue(board, 13, "O") &&
    !doesObjectExistAtIndexWithValue(board, 13, "X")
  ) {
    return 13;
  }
  if (
    doesObjectExistAtIndexWithValue(board, 13, "X") &&
    doesObjectExistAtIndexWithValue(board, 12, "X") &&
    !doesObjectExistAtIndexWithValue(board, 11, "O") &&
    !doesObjectExistAtIndexWithValue(board, 11, "X")
  ) {
    return 11;
  }
  if (
    doesObjectExistAtIndexWithValue(board, 16, "X") &&
    doesObjectExistAtIndexWithValue(board, 17, "X") &&
    !doesObjectExistAtIndexWithValue(board, 18, "O") &&
    !doesObjectExistAtIndexWithValue(board, 18, "X")
  ) {
    return 18;
  }
  if (
    doesObjectExistAtIndexWithValue(board, 17, "X") &&
    doesObjectExistAtIndexWithValue(board, 18, "X") &&
    !doesObjectExistAtIndexWithValue(board, 16, "O") &&
    !doesObjectExistAtIndexWithValue(board, 16, "X")
  ) {
    return 16;
  }
  if (
    doesObjectExistAtIndexWithValue(board, 21, "X") &&
    doesObjectExistAtIndexWithValue(board, 22, "X") &&
    !doesObjectExistAtIndexWithValue(board, 23, "O") &&
    !doesObjectExistAtIndexWithValue(board, 23, "X")
  ) {
    return 23;
  }
  if (
    doesObjectExistAtIndexWithValue(board, 22, "X") &&
    doesObjectExistAtIndexWithValue(board, 23, "X") &&
    !doesObjectExistAtIndexWithValue(board, 21, "O") &&
    !doesObjectExistAtIndexWithValue(board, 21, "X")
  ) {
    return 21;
  }

  if (
    doesObjectExistAtIndexWithValue(board, 5, "X") &&
    doesObjectExistAtIndexWithValue(board, 10, "X") &&
    !doesObjectExistAtIndexWithValue(board, 15, "O") &&
    !doesObjectExistAtIndexWithValue(board, 15, "X")
  ) {
    return 15;
  }

  if (
    doesObjectExistAtIndexWithValue(board, 10, "X") &&
    doesObjectExistAtIndexWithValue(board, 15, "X") &&
    !doesObjectExistAtIndexWithValue(board, 5, "O") &&
    !doesObjectExistAtIndexWithValue(board, 5, "X")
  ) {
    return 5;
  }

  if (
    doesObjectExistAtIndexWithValue(board, 6, "X") &&
    doesObjectExistAtIndexWithValue(board, 11, "X") &&
    !doesObjectExistAtIndexWithValue(board, 16, "O") &&
    !doesObjectExistAtIndexWithValue(board, 16, "X")
  ) {
    return 16;
  }
  if (
    doesObjectExistAtIndexWithValue(board, 11, "X") &&
    doesObjectExistAtIndexWithValue(board, 16, "X") &&
    !doesObjectExistAtIndexWithValue(board, 6, "O") &&
    !doesObjectExistAtIndexWithValue(board, 6, "X")
  ) {
    return 6;
  }
  if (
    doesObjectExistAtIndexWithValue(board, 7, "X") &&
    doesObjectExistAtIndexWithValue(board, 12, "X") &&
    !doesObjectExistAtIndexWithValue(board, 17, "O") &&
    !doesObjectExistAtIndexWithValue(board, 17, "X")
  ) {
    return 17;
  }
  if (
    doesObjectExistAtIndexWithValue(board, 12, "X") &&
    doesObjectExistAtIndexWithValue(board, 17, "X") &&
    !doesObjectExistAtIndexWithValue(board, 7, "O") &&
    !doesObjectExistAtIndexWithValue(board, 7, "X")
  ) {
    return 7;
  }
  if (
    doesObjectExistAtIndexWithValue(board, 8, "X") &&
    doesObjectExistAtIndexWithValue(board, 13, "X") &&
    !doesObjectExistAtIndexWithValue(board, 18, "O") &&
    !doesObjectExistAtIndexWithValue(board, 18, "X")
  ) {
    return 18;
  }
  if (
    doesObjectExistAtIndexWithValue(board, 13, "X") &&
    doesObjectExistAtIndexWithValue(board, 18, "X") &&
    !doesObjectExistAtIndexWithValue(board, 8, "O") &&
    !doesObjectExistAtIndexWithValue(board, 8, "X")
  ) {
    return 8;
  }
  if (
    doesObjectExistAtIndexWithValue(board, 9, "X") &&
    doesObjectExistAtIndexWithValue(board, 14, "X") &&
    !doesObjectExistAtIndexWithValue(board, 19, "O") &&
    !doesObjectExistAtIndexWithValue(board, 19, "X")
  ) {
    return 19;
  }

  for (let i = 0; i < 25; i++) {
    if (board[i] === "") {
      const newBoard = [...board];
      newBoard[i] = "O";
      if (calculateWinnerMatrix5(newBoard)) {
        return i;
      }
      newBoard[i] = "";
    }
  }

  for (let i = 0; i < 25; i++) {
    if (board[i] === "") {
      const newBoard = [...board];
      newBoard[i] = "X";
      if (calculateWinnerMatrix5(newBoard)) {
        return i;
      }
      newBoard[i] = "";
    }
  }

  function randomIndex() {
    const randomNumber = Math.floor(Math.random() * 25);
    console.log(randomNumber, "random");
    const check = board.every((item) => item !== "");
    console.log(check);
    if (board[randomNumber] == "") {
      console.log("execute here!", randomNumber);
      return randomNumber;
    } else if (check) {
      console.log("exit", "-----draw");
      return;
    } else {
      console.log("recall");
      return randomIndex();
    }
  }

  return randomIndex();
};


/* 

  MATRIX 7 GAME LOGIC

*/


const winningCombinationsForMatrix7 = [
  [0, 1, 2, 3, 4],
  [1, 2, 3, 4, 5],
  [2, 3, 4, 5, 6],
  [7, 8, 9, 10, 11],
  [8, 9, 10, 11, 12],
  [9, 10, 11, 12, 13],
  [14, 15, 16, 17, 18],
  [15, 16, 17, 18, 19],
  [16, 17, 18, 19, 20],
  [21, 22, 23, 24, 25],
  [22, 23, 24, 25, 26],
  [23, 24, 25, 26, 27],
  [28, 29, 30, 31, 32],
  [29, 30, 31, 32, 33],
  [30, 31, 32, 33, 34],
  [35, 36, 37, 38, 39],
  [36, 37, 38, 39, 40],
  [37, 38, 39, 40, 41],
  [42, 43, 44, 45, 46],
  [43, 44, 45, 46, 47],
  [44, 45, 46, 47, 48],
  [0, 7, 14, 21, 28],
  [7, 14, 21, 28, 35],
  [14, 21, 28, 35, 42],
  [1, 8, 15, 22, 29],
  [8, 15, 22, 29, 36],
  [15, 22, 29, 36, 43],
  [2, 9, 16, 23, 30],
  [9, 16, 23, 30, 37],
  [16, 23, 30, 37, 44],
  [3, 10, 17, 24, 31],
  [10, 17, 24, 31, 38],
  [17, 24, 31, 38, 45],
  [4, 11, 18, 25, 32],
  [11, 18, 25, 32, 39],
  [18, 25, 32, 39, 46],
  [5, 12, 19, 26, 33],
  [12, 19, 26, 33, 40],
  [19, 26, 33, 40, 47],
  [6, 13, 20, 27, 34],
  [13, 20, 27, 34, 41],
  [20, 27, 34, 41, 48],
  [0, 8, 16, 24, 32],
  [8, 16, 24, 32, 40],
  [16, 24, 32, 40, 48],
  [1, 9, 17, 25, 33],
  [9, 17, 25, 33, 41],
  [2, 10, 18, 26, 34],
  [7, 15, 23, 31, 39],
  [15, 23, 31, 39, 47],
  [14, 22, 30, 38, 46],
  [6, 12, 18, 24, 30],
  [12, 18, 24, 30, 36],
  [18, 24, 30, 36, 42],
  [5, 11, 17, 23, 29],
  [11, 17, 23, 29, 35],
  [13, 19, 25, 31, 37],
  [19, 25, 31, 37, 43],
  [20, 26, 32, 38, 44],
  [4, 10, 16, 22, 28],
];

export const calculateWinnerMatrix7 = (board) => {
  for (let combination of winningCombinationsForMatrix7) {
    const [a, b, c, d, e] = combination;
    if (
      board[a] &&
      board[b] &&
      board[c] &&
      board[d] &&
      board[e] &&
      board[a] === board[b] &&
      board[a] === board[c] &&
      board[a] === board[d] &&
      board[a] === board[e]
    ) {
      console.log(board[a], "winner");
      return board[b];
    }
  }

  return null;
};

export const getSmartComputerMoveMatrix7 = (board) => {
  if (
    doesObjectExistAtIndexWithValue(board, 2, "X") &&
    doesObjectExistAtIndexWithValue(board, 3, "X") &&
    doesObjectExistAtIndexWithValue(board, 1, "X") &&
    !doesObjectExistAtIndexWithValue(board, 4, "O") &&
    !doesObjectExistAtIndexWithValue(board, 4, "X")
  ) {
    return 4;
  }

  if (
    doesObjectExistAtIndexWithValue(board, 1, "X") &&
    doesObjectExistAtIndexWithValue(board, 2, "X") &&
    doesObjectExistAtIndexWithValue(board, 4, "X") &&
    !doesObjectExistAtIndexWithValue(board, 3, "O") &&
    !doesObjectExistAtIndexWithValue(board, 3, "X")
  ) {
    return 3;
  }
  if (
    doesObjectExistAtIndexWithValue(board, 3, "X") &&
    doesObjectExistAtIndexWithValue(board, 4, "X") &&
    doesObjectExistAtIndexWithValue(board, 5, "X") &&
    !doesObjectExistAtIndexWithValue(board, 2, "O") &&
    !doesObjectExistAtIndexWithValue(board, 2, "X")
  ) {
    return 2;
  }
  if (
    doesObjectExistAtIndexWithValue(board, 8, "X") &&
    doesObjectExistAtIndexWithValue(board, 9, "X") &&
    doesObjectExistAtIndexWithValue(board, 10, "X") &&
    !doesObjectExistAtIndexWithValue(board, 11, "O") &&
    !doesObjectExistAtIndexWithValue(board, 11, "X")
  ) {
    return 11;
  }
  if (
    doesObjectExistAtIndexWithValue(board, 10, "X") &&
    doesObjectExistAtIndexWithValue(board, 11, "X") &&
    doesObjectExistAtIndexWithValue(board, 12, "X") &&
    !doesObjectExistAtIndexWithValue(board, 9, "O") &&
    !doesObjectExistAtIndexWithValue(board, 9, "X")
  ) {
    return 9;
  }
  if (
    doesObjectExistAtIndexWithValue(board, 15, "X") &&
    doesObjectExistAtIndexWithValue(board, 16, "X") &&
    doesObjectExistAtIndexWithValue(board, 17, "X") &&
    !doesObjectExistAtIndexWithValue(board, 18, "O") &&
    !doesObjectExistAtIndexWithValue(board, 18, "X")
  ) {
    return 18;
  }
  if (
    doesObjectExistAtIndexWithValue(board, 18, "X") &&
    doesObjectExistAtIndexWithValue(board, 19, "X") &&
    doesObjectExistAtIndexWithValue(board, 17, "X") &&
    !doesObjectExistAtIndexWithValue(board, 16, "O") &&
    !doesObjectExistAtIndexWithValue(board, 16, "X")
  ) {
    return 16;
  }
  if (
    doesObjectExistAtIndexWithValue(board, 22, "X") &&
    doesObjectExistAtIndexWithValue(board, 23, "X") &&
    doesObjectExistAtIndexWithValue(board, 24, "X") &&
    !doesObjectExistAtIndexWithValue(board, 25, "O") &&
    !doesObjectExistAtIndexWithValue(board, 25, "X")
  ) {
    return 25;
  }

  if (
    doesObjectExistAtIndexWithValue(board, 24, "X") &&
    doesObjectExistAtIndexWithValue(board, 25, "X") &&
    doesObjectExistAtIndexWithValue(board, 26, "X") &&
    !doesObjectExistAtIndexWithValue(board, 23, "O") &&
    !doesObjectExistAtIndexWithValue(board, 23, "X")
  ) {
    return 23;
  }
  if (
    doesObjectExistAtIndexWithValue(board, 29, "X") &&
    doesObjectExistAtIndexWithValue(board, 30, "X") &&
    doesObjectExistAtIndexWithValue(board, 31, "X") &&
    !doesObjectExistAtIndexWithValue(board, 32, "O") &&
    !doesObjectExistAtIndexWithValue(board, 32, "X")
  ) {
    return 32;
  }
  if (
    doesObjectExistAtIndexWithValue(board, 36, "X") &&
    doesObjectExistAtIndexWithValue(board, 37, "X") &&
    doesObjectExistAtIndexWithValue(board, 38, "X") &&
    !doesObjectExistAtIndexWithValue(board, 39, "O") &&
    !doesObjectExistAtIndexWithValue(board, 39, "X")
  ) {
    return 39;
  }
  if (
    doesObjectExistAtIndexWithValue(board, 38, "X") &&
    doesObjectExistAtIndexWithValue(board, 39, "X") &&
    doesObjectExistAtIndexWithValue(board, 40, "X") &&
    !doesObjectExistAtIndexWithValue(board, 37, "O") &&
    !doesObjectExistAtIndexWithValue(board, 37, "X")
  ) {
    return 37;
  }

  if (
    doesObjectExistAtIndexWithValue(board, 43, "X") &&
    doesObjectExistAtIndexWithValue(board, 44, "X") &&
    doesObjectExistAtIndexWithValue(board, 45, "X") &&
    !doesObjectExistAtIndexWithValue(board, 46, "O") &&
    !doesObjectExistAtIndexWithValue(board, 46, "X")
  ) {
    return 46;
  }
  if (
    doesObjectExistAtIndexWithValue(board, 45, "X") &&
    doesObjectExistAtIndexWithValue(board, 46, "X") &&
    doesObjectExistAtIndexWithValue(board, 47, "X") &&
    !doesObjectExistAtIndexWithValue(board, 44, "O") &&
    !doesObjectExistAtIndexWithValue(board, 44, "X")
  ) {
    return 44;
  }
  if (
    doesObjectExistAtIndexWithValue(board, 7, "X") &&
    doesObjectExistAtIndexWithValue(board, 14, "X") &&
    doesObjectExistAtIndexWithValue(board, 21, "X") &&
    !doesObjectExistAtIndexWithValue(board, 28, "O") &&
    !doesObjectExistAtIndexWithValue(board, 28, "X")
  ) {
    return 28;
  }
  if (
    doesObjectExistAtIndexWithValue(board, 21, "X") &&
    doesObjectExistAtIndexWithValue(board, 28, "X") &&
    doesObjectExistAtIndexWithValue(board, 35, "X") &&
    !doesObjectExistAtIndexWithValue(board, 14, "O") &&
    !doesObjectExistAtIndexWithValue(board, 14, "X")
  ) {
    return 14;
  }
  if (
    doesObjectExistAtIndexWithValue(board, 8, "X") &&
    doesObjectExistAtIndexWithValue(board, 15, "X") &&
    doesObjectExistAtIndexWithValue(board, 22, "X") &&
    !doesObjectExistAtIndexWithValue(board, 29, "O") &&
    !doesObjectExistAtIndexWithValue(board, 29, "X")
  ) {
    return 29;
  }

  if (
    doesObjectExistAtIndexWithValue(board, 22, "X") &&
    doesObjectExistAtIndexWithValue(board, 29, "X") &&
    doesObjectExistAtIndexWithValue(board, 36, "X") &&
    !doesObjectExistAtIndexWithValue(board, 15, "O") &&
    !doesObjectExistAtIndexWithValue(board, 15, "X")
  ) {
    return 15;
  }
  if (
    doesObjectExistAtIndexWithValue(board, 9, "X") &&
    doesObjectExistAtIndexWithValue(board, 16, "X") &&
    doesObjectExistAtIndexWithValue(board, 23, "X") &&
    !doesObjectExistAtIndexWithValue(board, 30, "O") &&
    !doesObjectExistAtIndexWithValue(board, 30, "X")
  ) {
    return 30;
  }
  if (
    doesObjectExistAtIndexWithValue(board, 23, "X") &&
    doesObjectExistAtIndexWithValue(board, 30, "X") &&
    doesObjectExistAtIndexWithValue(board, 37, "X") &&
    !doesObjectExistAtIndexWithValue(board, 16, "O") &&
    !doesObjectExistAtIndexWithValue(board, 16, "X")
  ) {
    return 16;
  }
  if (
    doesObjectExistAtIndexWithValue(board, 10, "X") &&
    doesObjectExistAtIndexWithValue(board, 17, "X") &&
    doesObjectExistAtIndexWithValue(board, 24, "X") &&
    !doesObjectExistAtIndexWithValue(board, 31, "O") &&
    !doesObjectExistAtIndexWithValue(board, 31, "X")
  ) {
    return 31;
  }
  if (
    doesObjectExistAtIndexWithValue(board, 24, "X") &&
    doesObjectExistAtIndexWithValue(board, 31, "X") &&
    doesObjectExistAtIndexWithValue(board, 38, "X") &&
    !doesObjectExistAtIndexWithValue(board, 17, "O") &&
    !doesObjectExistAtIndexWithValue(board, 17, "X")
  ) {
    return 17;
  }
  if (
    doesObjectExistAtIndexWithValue(board, 11, "X") &&
    doesObjectExistAtIndexWithValue(board, 18, "X") &&
    doesObjectExistAtIndexWithValue(board, 25, "X") &&
    !doesObjectExistAtIndexWithValue(board, 32, "O") &&
    !doesObjectExistAtIndexWithValue(board, 32, "X")
  ) {
    return 32;
  }
  if (
    doesObjectExistAtIndexWithValue(board, 25, "X") &&
    doesObjectExistAtIndexWithValue(board, 32, "X") &&
    doesObjectExistAtIndexWithValue(board, 39, "X") &&
    !doesObjectExistAtIndexWithValue(board, 18, "O") &&
    !doesObjectExistAtIndexWithValue(board, 18, "X")
  ) {
    return 18;
  }
  if (
    doesObjectExistAtIndexWithValue(board, 12, "X") &&
    doesObjectExistAtIndexWithValue(board, 19, "X") &&
    doesObjectExistAtIndexWithValue(board, 26, "X") &&
    !doesObjectExistAtIndexWithValue(board, 33, "O") &&
    !doesObjectExistAtIndexWithValue(board, 33, "X")
  ) {
    return 33;
  }
  if (
    doesObjectExistAtIndexWithValue(board, 26, "X") &&
    doesObjectExistAtIndexWithValue(board, 33, "X") &&
    doesObjectExistAtIndexWithValue(board, 40, "X") &&
    !doesObjectExistAtIndexWithValue(board, 19, "O") &&
    !doesObjectExistAtIndexWithValue(board, 19, "X")
  ) {
    return 19;
  }
  if (
    doesObjectExistAtIndexWithValue(board, 13, "X") &&
    doesObjectExistAtIndexWithValue(board, 20, "X") &&
    doesObjectExistAtIndexWithValue(board, 27, "X") &&
    !doesObjectExistAtIndexWithValue(board, 34, "O") &&
    !doesObjectExistAtIndexWithValue(board, 34, "X")
  ) {
    return 34;
  }
  if (
    doesObjectExistAtIndexWithValue(board, 27, "X") &&
    doesObjectExistAtIndexWithValue(board, 34, "X") &&
    doesObjectExistAtIndexWithValue(board, 41, "X") &&
    !doesObjectExistAtIndexWithValue(board, 20, "O") &&
    !doesObjectExistAtIndexWithValue(board, 20, "X")
  ) {
    return 20;
  }
  if (
    doesObjectExistAtIndexWithValue(board, 8, "X") &&
    doesObjectExistAtIndexWithValue(board, 16, "X") &&
    doesObjectExistAtIndexWithValue(board, 24, "X") &&
    !doesObjectExistAtIndexWithValue(board, 32, "O") &&
    !doesObjectExistAtIndexWithValue(board, 32, "X")
  ) {
    return 32;
  }
  if (
    doesObjectExistAtIndexWithValue(board, 24, "X") &&
    doesObjectExistAtIndexWithValue(board, 32, "X") &&
    doesObjectExistAtIndexWithValue(board, 40, "X") &&
    !doesObjectExistAtIndexWithValue(board, 16, "O") &&
    !doesObjectExistAtIndexWithValue(board, 16, "X")
  ) {
    return 16;
  }

  if (
    doesObjectExistAtIndexWithValue(board, 12, "X") &&
    doesObjectExistAtIndexWithValue(board, 18, "X") &&
    doesObjectExistAtIndexWithValue(board, 24, "X") &&
    !doesObjectExistAtIndexWithValue(board, 30, "O") &&
    !doesObjectExistAtIndexWithValue(board, 30, "X")
  ) {
    return 30;
  }
  if (
    doesObjectExistAtIndexWithValue(board, 24, "X") &&
    doesObjectExistAtIndexWithValue(board, 30, "X") &&
    doesObjectExistAtIndexWithValue(board, 36, "X") &&
    !doesObjectExistAtIndexWithValue(board, 18, "O") &&
    !doesObjectExistAtIndexWithValue(board, 18, "X")
  ) {
    return 18;
  }
  if (
    doesObjectExistAtIndexWithValue(board, 11, "X") &&
    doesObjectExistAtIndexWithValue(board, 17, "X") &&
    doesObjectExistAtIndexWithValue(board, 23, "X") &&
    !doesObjectExistAtIndexWithValue(board, 29, "O") &&
    !doesObjectExistAtIndexWithValue(board, 29, "X")
  ) {
    return 29;
  }
  if (
    doesObjectExistAtIndexWithValue(board, 17, "X") &&
    doesObjectExistAtIndexWithValue(board, 23, "X") &&
    doesObjectExistAtIndexWithValue(board, 29, "X") &&
    !doesObjectExistAtIndexWithValue(board, 11, "O") &&
    !doesObjectExistAtIndexWithValue(board, 11, "X")
  ) {
    return 11;
  }
  if (
    doesObjectExistAtIndexWithValue(board, 19, "X") &&
    doesObjectExistAtIndexWithValue(board, 25, "X") &&
    doesObjectExistAtIndexWithValue(board, 31, "X") &&
    !doesObjectExistAtIndexWithValue(board, 37, "O") &&
    !doesObjectExistAtIndexWithValue(board, 37, "X")
  ) {
    return 37;
  }
  if (
    doesObjectExistAtIndexWithValue(board, 25, "X") &&
    doesObjectExistAtIndexWithValue(board, 31, "X") &&
    doesObjectExistAtIndexWithValue(board, 37, "X") &&
    !doesObjectExistAtIndexWithValue(board, 19, "O") &&
    !doesObjectExistAtIndexWithValue(board, 19, "X")
  ) {
    return 19;
  }

  if (
    doesObjectExistAtIndexWithValue(board, 9, "X") &&
    doesObjectExistAtIndexWithValue(board, 17, "X") &&
    doesObjectExistAtIndexWithValue(board, 25, "X") &&
    !doesObjectExistAtIndexWithValue(board, 33, "O") &&
    !doesObjectExistAtIndexWithValue(board, 33, "X")
  ) {
    return 33;
  }
  if (
    doesObjectExistAtIndexWithValue(board, 17, "X") &&
    doesObjectExistAtIndexWithValue(board, 25, "X") &&
    doesObjectExistAtIndexWithValue(board, 33, "X") &&
    !doesObjectExistAtIndexWithValue(board, 9, "O") &&
    !doesObjectExistAtIndexWithValue(board, 9, "X")
  ) {
    return 9;
  }
  if (
    doesObjectExistAtIndexWithValue(board, 15, "X") &&
    doesObjectExistAtIndexWithValue(board, 23, "X") &&
    doesObjectExistAtIndexWithValue(board, 31, "X") &&
    !doesObjectExistAtIndexWithValue(board, 39, "O") &&
    !doesObjectExistAtIndexWithValue(board, 39, "X")
  ) {
    return 39;
  }
  if (
    doesObjectExistAtIndexWithValue(board, 23, "X") &&
    doesObjectExistAtIndexWithValue(board, 31, "X") &&
    doesObjectExistAtIndexWithValue(board, 39, "X") &&
    !doesObjectExistAtIndexWithValue(board, 15, "O") &&
    !doesObjectExistAtIndexWithValue(board, 15, "X")
  ) {
    return 15;
  }

  for (let i = 0; i < 49; i++) {
    if (board[i] === "") {
      const newBoard = [...board];
      newBoard[i] = "O";
      if (calculateWinnerMatrix7(newBoard)) {
        return i;
      }
      newBoard[i] = "";
    }
  }

  for (let i = 0; i < 49; i++) {
    if (board[i] === "") {
      const newBoard = [...board];
      newBoard[i] = "X";
      if (calculateWinnerMatrix7(newBoard)) {
        return i;
      }
      newBoard[i] = "";
    }
  }

  function randomIndex() {
    const randomNumber = Math.floor(Math.random() * 49);
    console.log(randomNumber, "random");
    const check = board.every((item) => item !== "");
    console.log(check);
    if (board[randomNumber] == "") {
      return randomNumber;
    } else if (check) {
      return;
    } else {
      console.log("recall");
      return randomIndex();
    }
  }
  return randomIndex();
};