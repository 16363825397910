import Lottie from "lottie-react";
import React from "react";
import leaderboardAnimation from "../Animations/leaderboardAnimation.json";
import award1Animation from "../Animations/award1.json";
import award2Animation from "../Animations/award2.json";
import award3Animation from "../Animations/award3.json";
import classes from "./Leaderboard.module.css";

const Leaderboard = ({ leaderboard }) => {
  return (
    <>
      <div
        className={`${classes.leaderboard_container}`}
        style={{ height: "30rem" }}
        id="leaderboard_section"
      >
        <div className={classes.leaderAnimation_container}>
          <Lottie
            animationData={leaderboardAnimation}
            loop={true}
            autoplay={true}
            className={classes.leaderboardAnimation}
          />
        </div>
        <div className={classes.leaderboard_positions_container}>
          <div className={classes.leaderboard_table_container}>
            <div className={classes.leaderboard_table}>
              <div className={classes.leaderboard_table_header}>
                <div className={classes.leaderboard_column1}>
                  <h3 className={classes.column_name}>Rank</h3>
                </div>
                <div className={classes.leaderboard_column2}>
                  <h3 className={classes.column_name}>User</h3>
                </div>
                <div className={classes.leaderboard_column3}>
                  <h3 className={classes.column_name}>Score</h3>
                </div>
              </div>

              {leaderboard.map((data, i) => {
                return (
                  <div className={classes.leaderboard_table_data} key={i}>
                    <div className={classes.leaderboard_row1}>
                      <h3 className={classes.row_name}>{i + 1}</h3>
                    </div>
                    <div className={classes.leaderboard_row2}>
                      <h3 className={classes.row_name}>{data?.ani}</h3>
                    </div>
                    <div className={classes.leaderboard_row3}>
                      <h3 className={classes.row_name}>{data?.points}</h3>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Leaderboard;
