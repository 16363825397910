import React, { useState, useEffect } from "react";
import classes from "./SinglePlayerGrid5Game.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  resetTiles5Slice,
  setBoard,
  setIsHumanTurn,
  setWinner,
} from "../slices/tiles5Slice";
import { closeModal, openModal } from "../slices/modalSlice";
import GameOverModal from "../components/GameOverModal";
import Audio from "../components/Audio";
import AudioForComputer from "../components/AudioForComputer";
import {
  calculateWinnerMatrix5,
  getSmartComputerMoveMatrix5,
} from "../utils/gameFunctions";

const SinglePlayerGrid5Game = () => {
  const [winType, setWinType] = useState("");

  const dispatch = useDispatch();
  const { board, isHumanTurn, winner } = useSelector(
    (state) => state.tiles5Slice
  );

  const { modal } = useSelector((state) => state.modalSlice);

  const [soundX, setSoundX] = useState(false);
  const [soundO, setSoundO] = useState(false);

  const handleCellClick = (index) => {
    if (board[index] === "" && isHumanTurn) {
      setSoundO(false);
      setSoundX(true);
      const newBoard = [...board];
      newBoard[index] = "X";
      dispatch(setBoard(newBoard));
      dispatch(setIsHumanTurn(false));
    }
  };

  const computerMove = () => {
    const checkWinner = calculateWinnerMatrix5(board);
    if (!checkWinner) {
      const moveIndex = getSmartComputerMoveMatrix5(board);
      const newBoard = [...board];
      newBoard[moveIndex] = "O";
      setTimeout(() => {
        setSoundX(false);
        setSoundO(true);
        dispatch(setBoard(newBoard));
        dispatch(setIsHumanTurn(true));
      }, 600);
    }
  };

  useEffect(() => {
    const checkWinner = calculateWinnerMatrix5(board);
    if (checkWinner) {
      dispatch(setWinner(`Player ${checkWinner} won the game!`));
    }
  }, [handleCellClick, computerMove]);

  if (winner) {
    dispatch(openModal());
  }

  useEffect(() => {
    const check = board.every((item) => item !== "");
    if (check) {
      setWinType("0");
      dispatch(openModal());
    }
  }, [board]);

  const closeModalHandler = () => {
    setWinType("");
    dispatch(closeModal());
  };

  return (
    <div className={classes.container}>
      <div className={classes.sub_container}>
        <div className={classes.game_title}>
          <div className={classes.title_image}>
            <img
              src="/assets/images/noughtsAndCrosses.png"
              className={classes.image}
              alt="....."
            />
          </div>
        </div>

        {soundX ? <Audio /> : soundO ? <AudioForComputer /> : null}

        <div className={classes.player_turn_indicator}>
          <h1>{isHumanTurn ? "Player X's Turn " : "Computer O's Turn"}</h1>
        </div>

        <div className={classes.grid_container}>
          <img
            src="/assets/images/background-grid.jpg"
            className={classes.bg_img}
          />
          <div className={classes.grid}>
            {board.map((cell, index) => (
              <div
                key={index}
                className={`
                ${
                  index === 0
                    ? classes.tile_one
                    : index === 1
                    ? classes.tile_two
                    : index === 2
                    ? classes.tile_three
                    : index === 3
                    ? classes.tile_four
                    : index === 4
                    ? classes.tile_five
                    : index === 5
                    ? classes.tile_six
                    : index === 6
                    ? classes.tile_seven
                    : index === 7
                    ? classes.tile_eight
                    : index === 8
                    ? classes.tile_nine
                    : index === 9
                    ? classes.tile_ten
                    : index === 10
                    ? classes.tile_eleven
                    : index === 11
                    ? classes.tile_twelve
                    : index === 12
                    ? classes.tile_thirteen
                    : index === 13
                    ? classes.tile_fourteen
                    : index === 14
                    ? classes.tile_fifteen
                    : index === 15
                    ? classes.tile_sixteen
                    : index === 16
                    ? classes.tile_seventeen
                    : index === 17
                    ? classes.tile_eighteen
                    : index === 18
                    ? classes.tile_nineteen
                    : index === 19
                    ? classes.tile_twenty
                    : index === 20
                    ? classes.tile_twenty_one
                    : index === 21
                    ? classes.tile_twenty_two
                    : index === 22
                    ? classes.tile_twenty_three
                    : index === 23
                    ? classes.tile_twenty_four
                    : index === 24
                    ? classes.tile_twenty_five
                    : index === 25
                    ? classes.tile_twenty_six
                    : index === 26
                    ? classes.tile_twenty_seven
                    : index === 27
                    ? classes.tile_twenty_eight
                    : index === 28
                    ? classes.tile_twenty_nine
                    : index === 29
                    ? classes.tile_thirty
                    : null
                }
              `}
                onClick={() => handleCellClick(index)}
                style={
                  cell === "X"
                    ? { color: "#e8d615" }
                    : cell === "O"
                    ? {
                        color: "white",
                      }
                    : null
                }
              >
                {cell}
              </div>
            ))}
            {isHumanTurn ? null : computerMove()}
          </div>
        </div>
        <div className={classes.info}>
          <h1>Place 4 in a row</h1>
        </div>
      </div>
      {modal && (
        <GameOverModal
          winner={winner}
          winType={winType}
          closeModalHandler={closeModalHandler}
        />
      )}
    </div>
  );
};

export default SinglePlayerGrid5Game;
