import React, { useEffect, useState } from "react";
import classes from "./LoginPage.module.css";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import { registerApi } from "../api/api";
import axios from "axios";
import { setUserCredentials } from "../slices/usersSlice";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { setUserPoints } from "../slices/pointsSlice";
import Loading from "../components/Loading";

const RegisterPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const clickHandler = async () => {
    if (username !== "" && password !== "") {
      try {
        setLoading(true);
        const data = { username: username, password: password };
        console.log(data, "data");
        const res = await axios.post(registerApi, data);
        dispatch(setUserCredentials(res?.data));
        dispatch(setUserPoints(res?.data?.points));
        setLoading(false);
        navigate("/");
      } catch (error) {
        setLoading(false);
        toast.error(
          error?.response?.data?.message ||
            error?.data?.message ||
            error?.message ||
            error
        );
      }
    } else {
      toast.error("Enter Your Phone No. and Password");
    }
  };

  return (
    <>
      <div className={classes.container}>
        <div className={classes.sub_container}>
          <div className={classes.game_title}>
            <img
              src="/assets/images/noughtsAndCrosses.png"
              className={classes.title}
              alt="....."
            />
          </div>

          <div className={classes.gridContainer}>
            <img
              src="/assets/images/loginBackground.webp"
              alt=""
              className={classes.bg_img}
              style={{
                borderRadius: "20px",
                marginTop: "2rem",
                height: "auto",
              }}
            />
            <div className={classes.grid}>
              <div className={classes.detail}>
                <label className={classes.label}>Mobile No.</label>
                <br />
                <input
                  className={classes.input}
                  type="text"
                  placeholder="Enter Your Phone No."
                  value={username}
                  onChange={(e) => {
                    setUsername(e.target.value);
                  }}
                />
              </div>
              <div className={classes.detail}>
                <label className={classes.label}>Password</label>
                <br />
                <input
                  className={classes.input}
                  type="password"
                  placeholder="Enter Password"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                />
              </div>
              {loading ? (
                <Loading />
              ) : (
                <>
                  <div className={classes.btnDiv}>
                    <button className={classes.btn} onClick={clickHandler}>
                      Register
                    </button>
                  </div>
                  <div className={classes.link_container}>
                    <Link to="/login" className={classes.link}>
                      Login
                    </Link>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegisterPage;
