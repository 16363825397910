import React, { useEffect, useState } from "react";
import classes from "./HomePage.module.css";
import { Link, useNavigate } from "react-router-dom";
import { reset } from "../utils/gameFunctions";
import { useDispatch, useSelector } from "react-redux";
import Navbar from "../components/Navbar";
import Leaderboard from "../components/Leaderboard";
import { toast } from "react-toastify";
import axios from "axios";
import { leaderboardApi } from "../api/api";
import Loading from "../components/Loading";

const HomePage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [leaderboard, setLeaderboard] = useState([]);
  const [loading, setLoading] = useState(true);
  const { userPoints } = useSelector((state) => state.pointsSlice);
  const navigateHandler = (path) => {
    reset(dispatch);
    navigate(path);
  };

  const getLeaderBoard = async () => {
    try {
      setLoading(true);
      const res = await axios.post(leaderboardApi);
      setLeaderboard(res?.data?.result);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(
        error?.response?.data?.message ||
          error?.data?.message ||
          error?.message ||
          error
      );
    }
  };

  useEffect(() => {
    getLeaderBoard();
  }, []);
  return (
    <>
      <Navbar />
      <div className={classes.container}>
        <div className={classes.game_title}>
          <div className={classes.title_image}>
            <img
              src="/assets/images/noughtsAndCrosses.png"
              className={classes.image}
              alt="....."
            />
          </div>
        </div>
        <div className={classes.game_mode_container}>
          <div className={classes.game_modes}>
            <button
              className={classes.multiplayer_btn}
              onClick={() => navigateHandler("/single-player/levels")}
            >
              1 Player
            </button>
            <button
              className={classes.computer_btn}
              onClick={() => navigateHandler("/multi-player/levels")}
            >
              2 Players
            </button>
          </div>
          <div className={classes.points_container}>
            <img
              src="/assets/images/star.png"
              alt=""
              className={classes.point_image}
            />
            {userPoints}
          </div>
        </div>

        <div className={classes.title_container}>
          <h3 className={classes.title}>Leaderboard</h3>
        </div>
        {loading ? <Loading /> : <Leaderboard leaderboard={leaderboard} />}
      </div>
    </>
  );
};

export default HomePage;
