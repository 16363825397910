import React from "react";
import classes from "./Navbar.module.css";
import { Link, useNavigate } from "react-router-dom";

const Navbar = () => {
  const navigate=useNavigate();
  return (
    <div className={classes.main}>
      <div className={classes.main2}>
        <div className={classes.game_title}>
          <img
            src="/assets/images/noughtsAndCrosses.png"
            className={classes.title}
            alt="....."
            onClick={()=>navigate("/")}
            style={{cursor:'pointer'}}
          />
        </div>
        <div className={classes.navItems}>
          <Link to="/">
            <span>Home</span>
          </Link>
          <Link to="/account">
            <span>Account</span>
          </Link>
          <Link to="/">
            <span>T&C</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
