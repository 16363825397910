import React, { useEffect, useState } from "react";
import classes from "./AccountPage.module.css";
import Lottie from "lottie-react";
import userAnimation from "../Animations/userAnimation.json";
import Navbar from "../components/Navbar";
import { toast } from "react-toastify";
import axios from "axios";
import { accountApi } from "../api/api";
import { useSelector } from "react-redux";
import Loading from "../components/Loading";

const AccountPage = () => {
  const { user } = useSelector((state) => state.usersSlice);
  const [ani, setAni] = useState("");
  const [score, setScore] = useState("");
  const [loading, setLoading] = useState(true);
  const getAccountDetails = async () => {
    try {
      setLoading(true);
      const res = await axios.post(accountApi, null, {
        headers: {
          Authorization: `Bearer ${user?.response}`,
        },
      });
      setAni(res?.data?.ani);
      setScore(res?.data?.result);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(
        error?.response?.data?.message ||
          error?.data?.message ||
          error?.message ||
          error
      );
    }
  };
  useEffect(() => {
    getAccountDetails();
  }, []);
  return (
    <>
      {" "}
      <Navbar />
      <div className={classes.container}>
        <div className={classes.accountHeading}>Account Details</div>
        {loading ? (
          <Loading />
        ) : (
          <>
            <div className={classes.main}>
              <div className={classes.animationDiv}>
                <Lottie
                  animationData={userAnimation}
                  className={classes.animation}
                />
                <p className={classes.userName}> {ani} </p>
              </div>
            </div>
            <div className={classes.Data}>
              <p>Score</p>
              <div className={classes.values}>{score}</div>
            </div>
          </>
        )}

      </div>
    </>
  );
};

export default AccountPage;
