import { createSlice } from "@reduxjs/toolkit";

const initialState = localStorage.getItem("points")
  ? JSON.parse(localStorage.getItem("points"))
  : {
      points: null,
      userPoints: null,
      pointsSaved: false,
    };

const pointsSlice = createSlice({
  name: "pointsSlice",
  initialState: initialState,
  reducers: {
    setPoints: (state, action) => {
      state.points = action.payload;
      localStorage.setItem("points", JSON.stringify(state));
      return state;
    },
    resetPoints: (state, action) => {
      state.points = null;
      localStorage.setItem("points", JSON.stringify(state));
      return state;
    },
    setUserPoints: (state, action) => {
      state.userPoints = action.payload;
      localStorage.setItem("points", JSON.stringify(state));
      return state;
    },
    resetUserPoints: (state, action) => {
      state.userPoints = null;
      state.points = null;
      localStorage.removeItem("points");
      return state;
    },
    setPointsSaved: (state, action) => {
      state.pointsSaved = true;
      localStorage.setItem("points", JSON.stringify(state));
      return state;
    },
    removePointsSaved: (state, action) => {
      state.pointsSaved = false;
      localStorage.setItem("points", JSON.stringify(state));
      return state;
    },
  },
});

export const {
  setPoints,
  resetPoints,
  setUserPoints,
  resetUserPoints,
  setPointsSaved,
  removePointsSaved,
} = pointsSlice.actions;
export default pointsSlice;
