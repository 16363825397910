import { createSlice } from "@reduxjs/toolkit";

const initialState = localStorage.getItem("tilesMediumMode")
  ? JSON.parse(localStorage.getItem("tilesMediumMode"))
  : {
      tilesMediumMode: [],
    };

const tilesSliceMediumMode = createSlice({
  name: "tilesSliceMediumMode",
  initialState: initialState,
  reducers: {
    setTilesMediumMode: (state, action) => {
      state.tilesMediumMode = [...state.tilesMediumMode, action.payload];
      localStorage.setItem("tilesMediumMode", JSON.stringify(state));
      return state;
    },
    setTilesMediumModeComputer: (state, action) => {
      const randomTile = () => {
        const getRandomNumber = () => {
          return Math.floor(Math.random() * 9) + 1;
        };

        let number = getRandomNumber();

        function doesObjectExistAtIndexWithValue(
          array,
          indexToCheck,
          valueToCheck
        ) {
          const foundObject = array.find((obj) => obj.index === indexToCheck);

          return foundObject && foundObject.value === valueToCheck;
        }

        if (
          doesObjectExistAtIndexWithValue(state.tilesMediumMode, 1, "O") &&
          doesObjectExistAtIndexWithValue(state.tilesMediumMode, 2, "O") &&
          !doesObjectExistAtIndexWithValue(state.tilesMediumMode, 3, "O") &&
          !doesObjectExistAtIndexWithValue(state.tilesMediumMode, 3, "X")
        ) {
          state.tilesMediumMode = [
            ...state.tilesMediumMode,
            { index: 3, value: "O" },
          ];
          localStorage.setItem("tilesMediumMode", JSON.stringify(state));
          return state;
        } else if (
          doesObjectExistAtIndexWithValue(state.tilesMediumMode, 4, "O") &&
          doesObjectExistAtIndexWithValue(state.tilesMediumMode, 5, "O") &&
          !doesObjectExistAtIndexWithValue(state.tilesMediumMode, 6, "O") &&
          !doesObjectExistAtIndexWithValue(state.tilesMediumMode, 6, "X")
        ) {
          state.tilesMediumMode = [
            ...state.tilesMediumMode,
            { index: 6, value: "O" },
          ];
          localStorage.setItem("tilesMediumMode", JSON.stringify(state));
          return state;
        } else if (
          doesObjectExistAtIndexWithValue(state.tilesMediumMode, 7, "O") &&
          doesObjectExistAtIndexWithValue(state.tilesMediumMode, 8, "O") &&
          !doesObjectExistAtIndexWithValue(state.tilesMediumMode, 9, "O") &&
          !doesObjectExistAtIndexWithValue(state.tilesMediumMode, 9, "X")
        ) {
          state.tilesMediumMode = [
            ...state.tilesMediumMode,
            { index: 9, value: "O" },
          ];
          localStorage.setItem("tilesMediumMode", JSON.stringify(state));
          return state;
        } else if (
          doesObjectExistAtIndexWithValue(state.tilesMediumMode, 1, "O") &&
          doesObjectExistAtIndexWithValue(state.tilesMediumMode, 4, "O") &&
          !doesObjectExistAtIndexWithValue(state.tilesMediumMode, 7, "O") &&
          !doesObjectExistAtIndexWithValue(state.tilesMediumMode, 7, "X")
        ) {
          state.tilesMediumMode = [
            ...state.tilesMediumMode,
            { index: 7, value: "O" },
          ];
          localStorage.setItem("tilesMediumMode", JSON.stringify(state));
          return state;
        } else if (
          doesObjectExistAtIndexWithValue(state.tilesMediumMode, 2, "O") &&
          doesObjectExistAtIndexWithValue(state.tilesMediumMode, 5, "O") &&
          !doesObjectExistAtIndexWithValue(state.tilesMediumMode, 8, "O") &&
          !doesObjectExistAtIndexWithValue(state.tilesMediumMode, 8, "X")
        ) {
          state.tilesMediumMode = [
            ...state.tilesMediumMode,
            { index: 8, value: "O" },
          ];
          localStorage.setItem("tilesMediumMode", JSON.stringify(state));
          return state;
        } else if (
          doesObjectExistAtIndexWithValue(state.tilesMediumMode, 3, "O") &&
          doesObjectExistAtIndexWithValue(state.tilesMediumMode, 6, "O") &&
          !doesObjectExistAtIndexWithValue(state.tilesMediumMode, 9, "O") &&
          !doesObjectExistAtIndexWithValue(state.tilesMediumMode, 9, "X")
        ) {
          state.tilesMediumMode = [
            ...state.tilesMediumMode,
            { index: 9, value: "O" },
          ];
          localStorage.setItem("tilesMediumMode", JSON.stringify(state));
          return state;
        } else if (
          doesObjectExistAtIndexWithValue(state.tilesMediumMode, 1, "O") &&
          doesObjectExistAtIndexWithValue(state.tilesMediumMode, 3, "O") &&
          !doesObjectExistAtIndexWithValue(state.tilesMediumMode, 2, "O") &&
          !doesObjectExistAtIndexWithValue(state.tilesMediumMode, 2, "X")
        ) {
          state.tilesMediumMode = [
            ...state.tilesMediumMode,
            { index: 2, value: "O" },
          ];
          localStorage.setItem("tilesMediumMode", JSON.stringify(state));
          return state;
        } else if (
          doesObjectExistAtIndexWithValue(state.tilesMediumMode, 4, "O") &&
          doesObjectExistAtIndexWithValue(state.tilesMediumMode, 6, "O") &&
          !doesObjectExistAtIndexWithValue(state.tilesMediumMode, 5, "O") &&
          !doesObjectExistAtIndexWithValue(state.tilesMediumMode, 5, "X")
        ) {
          state.tilesMediumMode = [
            ...state.tilesMediumMode,
            { index: 5, value: "O" },
          ];
          localStorage.setItem("tilesMediumMode", JSON.stringify(state));
          return state;
        } else if (
          doesObjectExistAtIndexWithValue(state.tilesMediumMode, 7, "O") &&
          doesObjectExistAtIndexWithValue(state.tilesMediumMode, 9, "O") &&
          !doesObjectExistAtIndexWithValue(state.tilesMediumMode, 8, "O") &&
          !doesObjectExistAtIndexWithValue(state.tilesMediumMode, 8, "X")
        ) {
          state.tilesMediumMode = [
            ...state.tilesMediumMode,
            { index: 8, value: "O" },
          ];
          localStorage.setItem("tilesMediumMode", JSON.stringify(state));
          return state;
        } else if (
          doesObjectExistAtIndexWithValue(state.tilesMediumMode, 1, "O") &&
          doesObjectExistAtIndexWithValue(state.tilesMediumMode, 7, "O") &&
          !doesObjectExistAtIndexWithValue(state.tilesMediumMode, 4, "O") &&
          !doesObjectExistAtIndexWithValue(state.tilesMediumMode, 4, "X")
        ) {
          state.tilesMediumMode = [
            ...state.tilesMediumMode,
            { index: 4, value: "O" },
          ];
          localStorage.setItem("tilesMediumMode", JSON.stringify(state));
          return state;
        } else if (
          doesObjectExistAtIndexWithValue(state.tilesMediumMode, 2, "O") &&
          doesObjectExistAtIndexWithValue(state.tilesMediumMode, 8, "O") &&
          !doesObjectExistAtIndexWithValue(state.tilesMediumMode, 5, "O") &&
          !doesObjectExistAtIndexWithValue(state.tilesMediumMode, 5, "X")
        ) {
          state.tilesMediumMode = [
            ...state.tilesMediumMode,
            { index: 5, value: "O" },
          ];
          localStorage.setItem("tilesMediumMode", JSON.stringify(state));
          return state;
        } else if (
          doesObjectExistAtIndexWithValue(state.tilesMediumMode, 3, "O") &&
          doesObjectExistAtIndexWithValue(state.tilesMediumMode, 9, "O") &&
          !doesObjectExistAtIndexWithValue(state.tilesMediumMode, 6, "O") &&
          !doesObjectExistAtIndexWithValue(state.tilesMediumMode, 6, "X")
        ) {
          state.tilesMediumMode = [
            ...state.tilesMediumMode,
            { index: 6, value: "O" },
          ];
          localStorage.setItem("tilesMediumMode", JSON.stringify(state));
          return state;
        } else if (
          doesObjectExistAtIndexWithValue(state.tilesMediumMode, 3, "O") &&
          doesObjectExistAtIndexWithValue(state.tilesMediumMode, 2, "O") &&
          !doesObjectExistAtIndexWithValue(state.tilesMediumMode, 1, "O") &&
          !doesObjectExistAtIndexWithValue(state.tilesMediumMode, 1, "X")
        ) {
          state.tilesMediumMode = [
            ...state.tilesMediumMode,
            { index: 1, value: "O" },
          ];
          localStorage.setItem("tilesMediumMode", JSON.stringify(state));
          return state;
        } else if (
          doesObjectExistAtIndexWithValue(state.tilesMediumMode, 6, "O") &&
          doesObjectExistAtIndexWithValue(state.tilesMediumMode, 5, "O") &&
          !doesObjectExistAtIndexWithValue(state.tilesMediumMode, 4, "O") &&
          !doesObjectExistAtIndexWithValue(state.tilesMediumMode, 4, "X")
        ) {
          state.tilesMediumMode = [
            ...state.tilesMediumMode,
            { index: 4, value: "O" },
          ];
          localStorage.setItem("tilesMediumMode", JSON.stringify(state));
          return state;
        } else if (
          doesObjectExistAtIndexWithValue(state.tilesMediumMode, 9, "O") &&
          doesObjectExistAtIndexWithValue(state.tilesMediumMode, 8, "O") &&
          !doesObjectExistAtIndexWithValue(state.tilesMediumMode, 7, "O") &&
          !doesObjectExistAtIndexWithValue(state.tilesMediumMode, 7, "X")
        ) {
          state.tilesMediumMode = [
            ...state.tilesMediumMode,
            { index: 7, value: "O" },
          ];
          localStorage.setItem("tilesMediumMode", JSON.stringify(state));
          return state;
        } else if (
          doesObjectExistAtIndexWithValue(state.tilesMediumMode, 7, "O") &&
          doesObjectExistAtIndexWithValue(state.tilesMediumMode, 4, "O") &&
          !doesObjectExistAtIndexWithValue(state.tilesMediumMode, 1, "O") &&
          !doesObjectExistAtIndexWithValue(state.tilesMediumMode, 1, "X")
        ) {
          state.tilesMediumMode = [
            ...state.tilesMediumMode,
            { index: 1, value: "O" },
          ];
          localStorage.setItem("tilesMediumMode", JSON.stringify(state));
          return state;
        } else if (
          doesObjectExistAtIndexWithValue(state.tilesMediumMode, 8, "O") &&
          doesObjectExistAtIndexWithValue(state.tilesMediumMode, 5, "O") &&
          !doesObjectExistAtIndexWithValue(state.tilesMediumMode, 2, "O") &&
          !doesObjectExistAtIndexWithValue(state.tilesMediumMode, 2, "X")
        ) {
          state.tilesMediumMode = [
            ...state.tilesMediumMode,
            { index: 2, value: "O" },
          ];
          localStorage.setItem("tilesMediumMode", JSON.stringify(state));
          return state;
        } else if (
          doesObjectExistAtIndexWithValue(state.tilesMediumMode, 9, "O") &&
          doesObjectExistAtIndexWithValue(state.tilesMediumMode, 6, "O") &&
          !doesObjectExistAtIndexWithValue(state.tilesMediumMode, 3, "O") &&
          !doesObjectExistAtIndexWithValue(state.tilesMediumMode, 3, "X")
        ) {
          state.tilesMediumMode = [
            ...state.tilesMediumMode,
            { index: 3, value: "O" },
          ];
          localStorage.setItem("tilesMediumMode", JSON.stringify(state));
          return state;
        } else if (
          doesObjectExistAtIndexWithValue(state.tilesMediumMode, 1, "O") &&
          doesObjectExistAtIndexWithValue(state.tilesMediumMode, 5, "O") &&
          !doesObjectExistAtIndexWithValue(state.tilesMediumMode, 9, "O") &&
          !doesObjectExistAtIndexWithValue(state.tilesMediumMode, 9, "X")
        ) {
          state.tilesMediumMode = [
            ...state.tilesMediumMode,
            { index: 9, value: "O" },
          ];
          localStorage.setItem("tilesMediumMode", JSON.stringify(state));
          return state;
        } else if (
          doesObjectExistAtIndexWithValue(state.tilesMediumMode, 1, "O") &&
          doesObjectExistAtIndexWithValue(state.tilesMediumMode, 9, "O") &&
          !doesObjectExistAtIndexWithValue(state.tilesMediumMode, 5, "O") &&
          !doesObjectExistAtIndexWithValue(state.tilesMediumMode, 5, "X")
        ) {
          state.tilesMediumMode = [
            ...state.tilesMediumMode,
            { index: 5, value: "O" },
          ];
          localStorage.setItem("tilesMediumMode", JSON.stringify(state));
          return state;
        } else if (
          doesObjectExistAtIndexWithValue(state.tilesMediumMode, 9, "O") &&
          doesObjectExistAtIndexWithValue(state.tilesMediumMode, 5, "O") &&
          !doesObjectExistAtIndexWithValue(state.tilesMediumMode, 1, "O") &&
          !doesObjectExistAtIndexWithValue(state.tilesMediumMode, 1, "X")
        ) {
          state.tilesMediumMode = [
            ...state.tilesMediumMode,
            { index: 1, value: "O" },
          ];
          localStorage.setItem("tilesMediumMode", JSON.stringify(state));
          return state;
        } else if (
          doesObjectExistAtIndexWithValue(state.tilesMediumMode, 3, "O") &&
          doesObjectExistAtIndexWithValue(state.tilesMediumMode, 5, "O") &&
          !doesObjectExistAtIndexWithValue(state.tilesMediumMode, 7, "O") &&
          !doesObjectExistAtIndexWithValue(state.tilesMediumMode, 7, "X")
        ) {
          state.tilesMediumMode = [
            ...state.tilesMediumMode,
            { index: 7, value: "O" },
          ];
          localStorage.setItem("tilesMediumMode", JSON.stringify(state));
          return state;
        } else if (
          doesObjectExistAtIndexWithValue(state.tilesMediumMode, 3, "O") &&
          doesObjectExistAtIndexWithValue(state.tilesMediumMode, 7, "O") &&
          !doesObjectExistAtIndexWithValue(state.tilesMediumMode, 5, "O") &&
          !doesObjectExistAtIndexWithValue(state.tilesMediumMode, 5, "X")
        ) {
          state.tilesMediumMode = [
            ...state.tilesMediumMode,
            { index: 5, value: "O" },
          ];
          localStorage.setItem("tilesMediumMode", JSON.stringify(state));
          return state;
        } else if (
          doesObjectExistAtIndexWithValue(state.tilesMediumMode, 7, "O") &&
          doesObjectExistAtIndexWithValue(state.tilesMediumMode, 5, "O") &&
          !doesObjectExistAtIndexWithValue(state.tilesMediumMode, 3, "O") &&
          !doesObjectExistAtIndexWithValue(state.tilesMediumMode, 3, "X")
        ) {
          state.tilesMediumMode = [
            ...state.tilesMediumMode,
            { index: 3, value: "O" },
          ];
          localStorage.setItem("tilesMediumMode", JSON.stringify(state));
          return state;
        }

        // HERE 0 LOGIC FINISHED

        // NOW X LOGIC BEGINS
        else if (
          doesObjectExistAtIndexWithValue(state.tilesMediumMode, 1, "X") &&
          doesObjectExistAtIndexWithValue(state.tilesMediumMode, 2, "X") &&
          !doesObjectExistAtIndexWithValue(state.tilesMediumMode, 3, "O") &&
          !doesObjectExistAtIndexWithValue(state.tilesMediumMode, 3, "X")
        ) {
          state.tilesMediumMode = [
            ...state.tilesMediumMode,
            { index: 3, value: "O" },
          ];
          localStorage.setItem("tilesMediumMode", JSON.stringify(state));
          return state;
        } else if (
          doesObjectExistAtIndexWithValue(state.tilesMediumMode, 4, "X") &&
          doesObjectExistAtIndexWithValue(state.tilesMediumMode, 5, "X") &&
          !doesObjectExistAtIndexWithValue(state.tilesMediumMode, 6, "O") &&
          !doesObjectExistAtIndexWithValue(state.tilesMediumMode, 6, "X")
        ) {
          state.tilesMediumMode = [
            ...state.tilesMediumMode,
            { index: 6, value: "O" },
          ];
          localStorage.setItem("tilesMediumMode", JSON.stringify(state));
          return state;
        } else if (
          doesObjectExistAtIndexWithValue(state.tilesMediumMode, 7, "X") &&
          doesObjectExistAtIndexWithValue(state.tilesMediumMode, 8, "X") &&
          !doesObjectExistAtIndexWithValue(state.tilesMediumMode, 9, "O") &&
          !doesObjectExistAtIndexWithValue(state.tilesMediumMode, 9, "X")
        ) {
          state.tilesMediumMode = [
            ...state.tilesMediumMode,
            { index: 9, value: "O" },
          ];
          localStorage.setItem("tilesMediumMode", JSON.stringify(state));
          return state;
        } else if (
          doesObjectExistAtIndexWithValue(state.tilesMediumMode, 1, "X") &&
          doesObjectExistAtIndexWithValue(state.tilesMediumMode, 4, "X") &&
          !doesObjectExistAtIndexWithValue(state.tilesMediumMode, 7, "O") &&
          !doesObjectExistAtIndexWithValue(state.tilesMediumMode, 7, "X")
        ) {
          state.tilesMediumMode = [
            ...state.tilesMediumMode,
            { index: 7, value: "O" },
          ];
          localStorage.setItem("tilesMediumMode", JSON.stringify(state));
          return state;
        } else if (
          doesObjectExistAtIndexWithValue(state.tilesMediumMode, 2, "X") &&
          doesObjectExistAtIndexWithValue(state.tilesMediumMode, 5, "X") &&
          !doesObjectExistAtIndexWithValue(state.tilesMediumMode, 8, "O") &&
          !doesObjectExistAtIndexWithValue(state.tilesMediumMode, 8, "X")
        ) {
          state.tilesMediumMode = [
            ...state.tilesMediumMode,
            { index: 8, value: "O" },
          ];
          localStorage.setItem("tilesMediumMode", JSON.stringify(state));
          return state;
        } else if (
          doesObjectExistAtIndexWithValue(state.tilesMediumMode, 3, "X") &&
          doesObjectExistAtIndexWithValue(state.tilesMediumMode, 6, "X") &&
          !doesObjectExistAtIndexWithValue(state.tilesMediumMode, 9, "O") &&
          !doesObjectExistAtIndexWithValue(state.tilesMediumMode, 9, "X")
        ) {
          state.tilesMediumMode = [
            ...state.tilesMediumMode,
            { index: 9, value: "O" },
          ];
          localStorage.setItem("tilesMediumMode", JSON.stringify(state));
          return state;
        } else if (
          doesObjectExistAtIndexWithValue(state.tilesMediumMode, 1, "X") &&
          doesObjectExistAtIndexWithValue(state.tilesMediumMode, 5, "X") &&
          !doesObjectExistAtIndexWithValue(state.tilesMediumMode, 9, "O") &&
          !doesObjectExistAtIndexWithValue(state.tilesMediumMode, 9, "X")
        ) {
          state.tilesMediumMode = [
            ...state.tilesMediumMode,
            { index: 9, value: "O" },
          ];
          localStorage.setItem("tilesMediumMode", JSON.stringify(state));
          return state;
        } else if (
          doesObjectExistAtIndexWithValue(state.tilesMediumMode, 3, "X") &&
          doesObjectExistAtIndexWithValue(state.tilesMediumMode, 5, "X") &&
          !doesObjectExistAtIndexWithValue(state.tilesMediumMode, 7, "O") &&
          !doesObjectExistAtIndexWithValue(state.tilesMediumMode, 7, "X")
        ) {
          state.tilesMediumMode = [
            ...state.tilesMediumMode,
            { index: 7, value: "O" },
          ];
          localStorage.setItem("tilesMediumMode", JSON.stringify(state));
          return state;
        } else if (
          doesObjectExistAtIndexWithValue(state.tilesMediumMode, 9, "X") &&
          doesObjectExistAtIndexWithValue(state.tilesMediumMode, 1, "X") &&
          !doesObjectExistAtIndexWithValue(state.tilesMediumMode, 5, "O") &&
          !doesObjectExistAtIndexWithValue(state.tilesMediumMode, 5, "X")
        ) {
          state.tilesMediumMode = [
            ...state.tilesMediumMode,
            { index: 5, value: "O" },
          ];
          localStorage.setItem("tilesMediumMode", JSON.stringify(state));
          return state;
        } else if (
          doesObjectExistAtIndexWithValue(state.tilesMediumMode, 9, "X") &&
          doesObjectExistAtIndexWithValue(state.tilesMediumMode, 5, "X") &&
          !doesObjectExistAtIndexWithValue(state.tilesMediumMode, 1, "O") &&
          !doesObjectExistAtIndexWithValue(state.tilesMediumMode, 1, "X")
        ) {
          state.tilesMediumMode = [
            ...state.tilesMediumMode,
            { index: 1, value: "O" },
          ];
          localStorage.setItem("tilesMediumMode", JSON.stringify(state));
          return state;
        } else if (
          doesObjectExistAtIndexWithValue(state.tilesMediumMode, 7, "X") &&
          doesObjectExistAtIndexWithValue(state.tilesMediumMode, 3, "X") &&
          !doesObjectExistAtIndexWithValue(state.tilesMediumMode, 5, "O") &&
          !doesObjectExistAtIndexWithValue(state.tilesMediumMode, 5, "X")
        ) {
          state.tilesMediumMode = [
            ...state.tilesMediumMode,
            { index: 5, value: "O" },
          ];
          localStorage.setItem("tilesMediumMode", JSON.stringify(state));
          return state;
        } else if (
          doesObjectExistAtIndexWithValue(state.tilesMediumMode, 7, "X") &&
          doesObjectExistAtIndexWithValue(state.tilesMediumMode, 5, "X") &&
          !doesObjectExistAtIndexWithValue(state.tilesMediumMode, 3, "O") &&
          !doesObjectExistAtIndexWithValue(state.tilesMediumMode, 3, "X")
        ) {
          state.tilesMediumMode = [
            ...state.tilesMediumMode,
            { index: 3, value: "O" },
          ];
          localStorage.setItem("tilesMediumMode", JSON.stringify(state));
          return state;
        } else if (
          doesObjectExistAtIndexWithValue(state.tilesMediumMode, 1, "X") &&
          doesObjectExistAtIndexWithValue(state.tilesMediumMode, 3, "X") &&
          !doesObjectExistAtIndexWithValue(state.tilesMediumMode, 2, "O") &&
          !doesObjectExistAtIndexWithValue(state.tilesMediumMode, 2, "X")
        ) {
          state.tilesMediumMode = [
            ...state.tilesMediumMode,
            { index: 2, value: "O" },
          ];
          localStorage.setItem("tilesMediumMode", JSON.stringify(state));
          return state;
        } else if (
          doesObjectExistAtIndexWithValue(state.tilesMediumMode, 4, "X") &&
          doesObjectExistAtIndexWithValue(state.tilesMediumMode, 6, "X") &&
          !doesObjectExistAtIndexWithValue(state.tilesMediumMode, 5, "O") &&
          !doesObjectExistAtIndexWithValue(state.tilesMediumMode, 5, "X")
        ) {
          state.tilesMediumMode = [
            ...state.tilesMediumMode,
            { index: 5, value: "O" },
          ];
          localStorage.setItem("tilesMediumMode", JSON.stringify(state));
          return state;
        } else if (
          doesObjectExistAtIndexWithValue(state.tilesMediumMode, 7, "X") &&
          doesObjectExistAtIndexWithValue(state.tilesMediumMode, 9, "X") &&
          !doesObjectExistAtIndexWithValue(state.tilesMediumMode, 8, "O") &&
          !doesObjectExistAtIndexWithValue(state.tilesMediumMode, 8, "X")
        ) {
          state.tilesMediumMode = [
            ...state.tilesMediumMode,
            { index: 8, value: "O" },
          ];
          localStorage.setItem("tilesMediumMode", JSON.stringify(state));
          return state;
        } else if (
          doesObjectExistAtIndexWithValue(state.tilesMediumMode, 3, "X") &&
          doesObjectExistAtIndexWithValue(state.tilesMediumMode, 2, "X") &&
          !doesObjectExistAtIndexWithValue(state.tilesMediumMode, 1, "O") &&
          !doesObjectExistAtIndexWithValue(state.tilesMediumMode, 1, "X")
        ) {
          state.tilesMediumMode = [
            ...state.tilesMediumMode,
            { index: 1, value: "O" },
          ];
          localStorage.setItem("tilesMediumMode", JSON.stringify(state));
          return state;
        } else if (
          doesObjectExistAtIndexWithValue(state.tilesMediumMode, 6, "X") &&
          doesObjectExistAtIndexWithValue(state.tilesMediumMode, 5, "X") &&
          !doesObjectExistAtIndexWithValue(state.tilesMediumMode, 4, "O") &&
          !doesObjectExistAtIndexWithValue(state.tilesMediumMode, 4, "X")
        ) {
          state.tilesMediumMode = [
            ...state.tilesMediumMode,
            { index: 4, value: "O" },
          ];
          localStorage.setItem("tilesMediumMode", JSON.stringify(state));
          return state;
        } else if (
          doesObjectExistAtIndexWithValue(state.tilesMediumMode, 9, "X") &&
          doesObjectExistAtIndexWithValue(state.tilesMediumMode, 8, "X") &&
          !doesObjectExistAtIndexWithValue(state.tilesMediumMode, 7, "O") &&
          !doesObjectExistAtIndexWithValue(state.tilesMediumMode, 7, "X")
        ) {
          state.tilesMediumMode = [
            ...state.tilesMediumMode,
            { index: 7, value: "O" },
          ];
          localStorage.setItem("tilesMediumMode", JSON.stringify(state));
          return state;
        } else if (
          doesObjectExistAtIndexWithValue(state.tilesMediumMode, 1, "X") &&
          doesObjectExistAtIndexWithValue(state.tilesMediumMode, 7, "X") &&
          !doesObjectExistAtIndexWithValue(state.tilesMediumMode, 4, "O") &&
          !doesObjectExistAtIndexWithValue(state.tilesMediumMode, 4, "X")
        ) {
          state.tilesMediumMode = [
            ...state.tilesMediumMode,
            { index: 4, value: "O" },
          ];
          localStorage.setItem("tilesMediumMode", JSON.stringify(state));
          return state;
        } else if (
          doesObjectExistAtIndexWithValue(state.tilesMediumMode, 2, "X") &&
          doesObjectExistAtIndexWithValue(state.tilesMediumMode, 8, "X") &&
          !doesObjectExistAtIndexWithValue(state.tilesMediumMode, 5, "O") &&
          !doesObjectExistAtIndexWithValue(state.tilesMediumMode, 5, "X")
        ) {
          state.tilesMediumMode = [
            ...state.tilesMediumMode,
            { index: 5, value: "O" },
          ];
          localStorage.setItem("tilesMediumMode", JSON.stringify(state));
          return state;
        } else if (
          doesObjectExistAtIndexWithValue(state.tilesMediumMode, 3, "X") &&
          doesObjectExistAtIndexWithValue(state.tilesMediumMode, 9, "X") &&
          !doesObjectExistAtIndexWithValue(state.tilesMediumMode, 6, "O") &&
          !doesObjectExistAtIndexWithValue(state.tilesMediumMode, 6, "X")
        ) {
          state.tilesMediumMode = [
            ...state.tilesMediumMode,
            { index: 6, value: "O" },
          ];
          localStorage.setItem("tilesMediumMode", JSON.stringify(state));
          return state;
        } else if (
          doesObjectExistAtIndexWithValue(state.tilesMediumMode, 7, "X") &&
          doesObjectExistAtIndexWithValue(state.tilesMediumMode, 4, "X") &&
          !doesObjectExistAtIndexWithValue(state.tilesMediumMode, 1, "O") &&
          !doesObjectExistAtIndexWithValue(state.tilesMediumMode, 1, "X")
        ) {
          state.tilesMediumMode = [
            ...state.tilesMediumMode,
            { index: 1, value: "O" },
          ];
          localStorage.setItem("tilesMediumMode", JSON.stringify(state));
          return state;
        } else if (
          doesObjectExistAtIndexWithValue(state.tilesMediumMode, 8, "X") &&
          doesObjectExistAtIndexWithValue(state.tilesMediumMode, 5, "X") &&
          !doesObjectExistAtIndexWithValue(state.tilesMediumMode, 2, "O") &&
          !doesObjectExistAtIndexWithValue(state.tilesMediumMode, 2, "X")
        ) {
          state.tilesMediumMode = [
            ...state.tilesMediumMode,
            { index: 2, value: "O" },
          ];
          localStorage.setItem("tilesMediumMode", JSON.stringify(state));
          return state;
        } else if (
          doesObjectExistAtIndexWithValue(state.tilesMediumMode, 9, "X") &&
          doesObjectExistAtIndexWithValue(state.tilesMediumMode, 6, "X") &&
          !doesObjectExistAtIndexWithValue(state.tilesMediumMode, 3, "O") &&
          !doesObjectExistAtIndexWithValue(state.tilesMediumMode, 3, "X")
        ) {
          state.tilesMediumMode = [
            ...state.tilesMediumMode,
            { index: 3, value: "O" },
          ];
          localStorage.setItem("tilesMediumMode", JSON.stringify(state));
          return state;
        } else if (
          !doesObjectExistAtIndexWithValue(
            state.tilesMediumMode,
            number,
            "X"
          ) &&
          !doesObjectExistAtIndexWithValue(state.tilesMediumMode, number, "O")
        ) {
          state.tilesMediumMode = [
            ...state.tilesMediumMode,
            { index: number, value: "O" },
          ];
          localStorage.setItem("tilesMediumMode", JSON.stringify(state));
          return state;
        } else if (state.tilesMediumMode.length == 9) {
          return;
        } else {
          return randomTile();
        }
      };
      randomTile();
    },
    resetTilesMediumMode: (state, action) => {
      state.tilesMediumMode = [];
      localStorage.removeItem("tilesMediumMode");
      return state;
    },
  },
});

export const {
  setTilesMediumMode,
  resetTilesMediumMode,
  setTilesMediumModeComputer,
} = tilesSliceMediumMode.actions;
export default tilesSliceMediumMode;
