import React from "react";

const AudioForComputer = () => {
  return (
    <audio id="backgroundMusic" autoPlay>
        <source src="/assets/sounds/o-sound.wav" type="audio/wav" />
    </audio>
  );
};

export default AudioForComputer;
