import React, { useState, useEffect } from "react";
import classes from "./SinglePlayerGrid7Game.module.css";
import { useDispatch, useSelector } from "react-redux";
import { setBoard, setIsHumanTurn, setWinner } from "../slices/tiles7Slice";
import { closeModal, openModal } from "../slices/modalSlice";
import GameOverModal from "../components/GameOverModal";
import Audio from "../components/Audio";
import AudioForComputer from "../components/AudioForComputer";
import { toggleTurn } from "../slices/playerSlice";
import { calculateWinnerMatrix7 } from "../utils/gameFunctions";
import GameOverModalMultiplayer from "../components/GameOverModalMultiplayer";

const MultiPlayerGrid7Game = () => {
  const dispatch = useDispatch();
  const [winType, setWinType] = useState("");
  const { board, winner } = useSelector(
    (state) => state.tiles7Slice
  );

  const { player1, computer } = useSelector((state) => state.playerSlice);

  const { modal } = useSelector((state) => state.modalSlice);
  const [soundX, setSoundX] = useState(false);
  const [soundO, setSoundO] = useState(false);

  const handleCellClick = (index) => {
    if (board[index] === "" && player1) {
      setSoundO(false);
      setSoundX(true);
      const newBoard = [...board];
      newBoard[index] = "X";
      dispatch(setBoard(newBoard));
      dispatch(toggleTurn());
    }
    if (board[index] === "" && computer) {
      const newBoard = [...board];
      newBoard[index] = "O";
      setSoundO(true);
      setSoundX(false);
      dispatch(setBoard(newBoard));
      dispatch(toggleTurn());
    }
  };

  useEffect(() => {
    const checkWinner = calculateWinnerMatrix7(board);
    if (checkWinner) {
      dispatch(setWinner(`Player ${checkWinner} won the game!`));
    }
  }, [handleCellClick]);

  if (winner) {
    dispatch(openModal());
  }

  useEffect(() => {
    const check = board.every((item) => item !== "");
    if (check) {
      setWinType("0");
      dispatch(openModal());
    }
  }, [board]);

  const closeModalHandler = () => {
    setWinType("");
    dispatch(closeModal());
  };

  return (
    <div className={classes.container}>
      <div className={classes.sub_container}>
      <div className={classes.game_title}>
          <div className={classes.title_image}>
            <img
              src="/assets/images/noughtsAndCrosses.png"
              className={classes.image}
              alt="....."
            />
          </div>
        </div>

        <div className={classes.player_turn_indicator}>
          <h1>{player1 ? "Player X's Turn " : "Player O's Turn"}</h1>
        </div>

        {soundX ? <Audio /> : soundO ? <AudioForComputer /> : null}

        <div className={classes.x_img_container}>
          <img
            src="/assets/images/bg_x.png"
            alt="X mark"
            className={classes.x_img}
          />
        </div>
        <div className={classes.circle_img_container}>
          <img
            src="/assets/images/bg_circle.png"
            alt="Circle mark"
            className={classes.circle_img}
          />
        </div>

        <div className={classes.grid_container}>
          <img
            src="/assets/images/background-grid.jpg"
            className={classes.bg_img}
          />
          <div className={classes.grid}>
            {board.map((cell, index) => (
              <div
                key={index}
                className={`
                ${
                  index === 0
                    ? classes.tile_one
                    : index === 1
                    ? classes.tile_two
                    : index === 2
                    ? classes.tile_three
                    : index === 3
                    ? classes.tile_four
                    : index === 4
                    ? classes.tile_five
                    : index === 5
                    ? classes.tile_six
                    : index === 6
                    ? classes.tile_seven
                    : index === 7
                    ? classes.tile_eight
                    : index === 8
                    ? classes.tile_nine
                    : index === 9
                    ? classes.tile_ten
                    : index === 10
                    ? classes.tile_eleven
                    : index === 11
                    ? classes.tile_twelve
                    : index === 12
                    ? classes.tile_thirteen
                    : index === 13
                    ? classes.tile_fourteen
                    : index === 14
                    ? classes.tile_fifteen
                    : index === 15
                    ? classes.tile_sixteen
                    : index === 16
                    ? classes.tile_seventeen
                    : index === 17
                    ? classes.tile_eighteen
                    : index === 18
                    ? classes.tile_nineteen
                    : index === 19
                    ? classes.tile_twenty
                    : index === 20
                    ? classes.tile_twenty_one
                    : index === 21
                    ? classes.tile_twenty_two
                    : index === 22
                    ? classes.tile_twenty_three
                    : index === 23
                    ? classes.tile_twenty_four
                    : index === 24
                    ? classes.tile_twenty_five
                    : index === 25
                    ? classes.tile_twenty_six
                    : index === 26
                    ? classes.tile_twenty_seven
                    : index === 27
                    ? classes.tile_twenty_eight
                    : index === 28
                    ? classes.tile_twenty_nine
                    : index === 29
                    ? classes.tile_thirty
                    : index === 30
                    ? classes.tile_thirty_one
                    : index === 31
                    ? classes.tile_thirty_two
                    : index === 32
                    ? classes.tile_thirty_three
                    : index === 33
                    ? classes.tile_thirty_four
                    : index === 34
                    ? classes.tile_thirty_five
                    : index === 35
                    ? classes.tile_thirty_six
                    : index === 36
                    ? classes.tile_thirty_seven
                    : index === 37
                    ? classes.tile_thirty_eight
                    : index === 38
                    ? classes.tile_thirty_nine
                    : index === 39
                    ? classes.tile_forty
                    : index === 40
                    ? classes.tile_forty_one
                    : index === 41
                    ? classes.tile_forty_two
                    : index === 42
                    ? classes.tile_forty_three
                    : index === 43
                    ? classes.tile_forty_four
                    : index === 44
                    ? classes.tile_forty_five
                    : index === 45
                    ? classes.tile_forty_six
                    : index === 46
                    ? classes.tile_forty_seven
                    : index === 47
                    ? classes.tile_forty_eight
                    : index === 48
                    ? classes.tile_forty_nine
                    : index === 49
                    ? classes.tile_forty_fifty
                    : null
                }
              `}
                onClick={() => handleCellClick(index)}
                style={
                  cell === "X"
                    ? { color: "#e8d615" }
                    : cell === "O"
                    ? {
                        color: "white",
                      }
                    : null
                }
              >
                {cell}
              </div>
            ))}
          </div>
        </div>
        <div className={classes.info}>
          <h1>Place 5 in a row</h1>
        </div>
      </div>
      {modal && (
        <GameOverModalMultiplayer
          winner={`${winner}`}
          winType={winType}
          closeModalHandler={closeModalHandler}
        />
      )}
    </div>
  );
};

export default MultiPlayerGrid7Game;
