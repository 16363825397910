import React from "react";
import classes from "./LevelsPage.module.css";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { reset } from "../utils/gameFunctions";
import Navbar from "../components/Navbar";

const MultiplayerLevelsPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleNavigate = (path) => {
    reset(dispatch);
    navigate(path);
  };
  return (
    <>
      <Navbar />
      <div className={classes.container}>
        <div className={classes.game_title}>
          <div className={classes.title_image}>
            <img
              src="/assets/images/noughtsAndCrosses.png"
              className={classes.image}
              alt="....."
            />
          </div>
        </div>

        <div className={classes.game_mode_container}>
          <button
            className={classes.easy_btn}
            onClick={() => handleNavigate("/multi-player/game-mode-3*3")}
          >
            3*3 Mode
          </button>
          <button
            className={classes.easy_btn}
            onClick={() => handleNavigate("/multi-player/game-mode-5*5")}
          >
            5*5 Mode
          </button>
          <button
            className={classes.easy_btn}
            onClick={() => handleNavigate("/multi-player/game-mode-7*7")}
          >
            7*7 Mode
          </button>
        </div>
      </div>
    </>
  );
};

export default MultiplayerLevelsPage;
