// const pre=`http://192.168.1.37:4041/`;
const pre=`/`;
// const pre=`https://www.tictac.promotrking.com/`

const loginApi=`${pre}user/login`;
export {loginApi};

const registerApi=`${pre}user/register`;
export {registerApi};

const levelsApi=`${pre}user/level`;
export {levelsApi};

const saveScoreApi=`${pre}api/v1/saveScore`;
export {saveScoreApi};

const accountApi=`${pre}api/v1/getScore`;
export {accountApi};

const leaderboardApi=`${pre}user/topLeadingPlayers`;
export {leaderboardApi};