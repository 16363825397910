import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

const get24HoursFromNow = () => {
  const date = new Date();
  date.setTime(date.getTime() + 24 * 60 * 60 * 1000);
  return date;
};

const initialState = Cookies.get("user")
  ? JSON.parse(Cookies.get("user"))
  : { user: null };

const usersSlice = createSlice({
  name: "usersSlice",
  initialState,
  reducers: {
    setUserCredentials: (state, action) => {
      state.user = action.payload;
      Cookies.set("user", JSON.stringify(state), {
        expires: get24HoursFromNow(),
      });
      return state;
    },
    removeUserCredentials: (state, action) => {
      state.user = null;
      Cookies.remove("user");
      return state;
    },
  },
});

export const { setUserCredentials, removeUserCredentials } = usersSlice.actions;
export default usersSlice;
