import React, { useEffect, useState } from "react";
import classes from "./SinglePlayerEasyMode.module.css";
import { useDispatch, useSelector } from "react-redux";
import { toggleTurn } from "../slices/playerSlice";
import GameOverModal from "../components/GameOverModal";
import { closeModal, openModal } from "../slices/modalSlice";
import {
  setTilesMediumMode,
  setTilesMediumModeComputer,
} from "../slices/tilesSliceMedium";
import { setGameOver } from "../slices/gameOverSlice";
import Audio from "../components/Audio";
import AudioForComputer from "../components/AudioForComputer";

const SinglePlayerMediumMode = () => {
  const dispatch = useDispatch();
  const { player1, computer } = useSelector((state) => state.playerSlice);
  const { tilesMediumMode } = useSelector(
    (state) => state.tilesSliceMediumMode
  );
  const { gameOver } = useSelector((state) => state.gameOverSlice);
  const [winner, setWinner] = useState("");
  const [winType, setWinType] = useState("");
  const { modal } = useSelector((state) => state.modalSlice);
  const [soundX, setSoundX] = useState(false);
  const [soundO, setSoundO] = useState(false);

  const openModalHandler = () => {
    dispatch(openModal());
  };

  const closeModalHandler = () => {
    setWinType("");
    setWinner("");
    dispatch(closeModal());
  };

  const handleTileClick = (data) => {
    setSoundO(false);
    setSoundX(true);
    const filled = tilesMediumMode.some((item) => item.index == data.index);
    if (player1 && !filled) {
      dispatch(setTilesMediumMode({ index: data.index, value: "X" }));
      dispatch(toggleTurn());
    }
  };

  function doesObjectExistAtIndexWithValue(array, indexToCheck, valueToCheck) {
    const foundObject = array.find((obj) => obj.index === indexToCheck);

    return foundObject && foundObject.value === valueToCheck;
  }

  useEffect(() => {
    if (
      doesObjectExistAtIndexWithValue(tilesMediumMode, 1, "X") &&
      doesObjectExistAtIndexWithValue(tilesMediumMode, 2, "X") &&
      doesObjectExistAtIndexWithValue(tilesMediumMode, 3, "X")
    ) {
      dispatch(setGameOver());
      setWinner("Player X won the game!");
      openModalHandler();
    }
    if (
      doesObjectExistAtIndexWithValue(tilesMediumMode, 1, "O") &&
      doesObjectExistAtIndexWithValue(tilesMediumMode, 2, "O") &&
      doesObjectExistAtIndexWithValue(tilesMediumMode, 3, "O")
    ) {
      dispatch(setGameOver());

      setWinner("Player O won the game!");
      openModalHandler();
    }
    if (
      doesObjectExistAtIndexWithValue(tilesMediumMode, 4, "X") &&
      doesObjectExistAtIndexWithValue(tilesMediumMode, 5, "X") &&
      doesObjectExistAtIndexWithValue(tilesMediumMode, 6, "X")
    ) {
      dispatch(setGameOver());
      setWinner("Player X won the game!");
      openModalHandler();
    }
    if (
      doesObjectExistAtIndexWithValue(tilesMediumMode, 4, "O") &&
      doesObjectExistAtIndexWithValue(tilesMediumMode, 5, "O") &&
      doesObjectExistAtIndexWithValue(tilesMediumMode, 6, "O")
    ) {
      dispatch(setGameOver());
      setWinner("Player O won the game!");
      openModalHandler();
    }
    if (
      doesObjectExistAtIndexWithValue(tilesMediumMode, 7, "X") &&
      doesObjectExistAtIndexWithValue(tilesMediumMode, 8, "X") &&
      doesObjectExistAtIndexWithValue(tilesMediumMode, 9, "X")
    ) {
      dispatch(setGameOver());
      setWinner("Player X won the game!");
      openModalHandler();
    }
    if (
      doesObjectExistAtIndexWithValue(tilesMediumMode, 7, "O") &&
      doesObjectExistAtIndexWithValue(tilesMediumMode, 8, "O") &&
      doesObjectExistAtIndexWithValue(tilesMediumMode, 9, "O")
    ) {
      dispatch(setGameOver());
      setWinner("Player O won the game!");
      openModalHandler();
    }
    if (
      doesObjectExistAtIndexWithValue(tilesMediumMode, 1, "X") &&
      doesObjectExistAtIndexWithValue(tilesMediumMode, 4, "X") &&
      doesObjectExistAtIndexWithValue(tilesMediumMode, 7, "X")
    ) {
      dispatch(setGameOver());
      setWinner("Player X won the game!");
      openModalHandler();
    }
    if (
      doesObjectExistAtIndexWithValue(tilesMediumMode, 1, "O") &&
      doesObjectExistAtIndexWithValue(tilesMediumMode, 4, "O") &&
      doesObjectExistAtIndexWithValue(tilesMediumMode, 7, "O")
    ) {
      dispatch(setGameOver());
      setWinner("Player O won the game!");
      openModalHandler();
    }
    if (
      doesObjectExistAtIndexWithValue(tilesMediumMode, 2, "X") &&
      doesObjectExistAtIndexWithValue(tilesMediumMode, 5, "X") &&
      doesObjectExistAtIndexWithValue(tilesMediumMode, 8, "X")
    ) {
      dispatch(setGameOver());
      setWinner("Player X won the game!");
      openModalHandler();
    }
    if (
      doesObjectExistAtIndexWithValue(tilesMediumMode, 2, "O") &&
      doesObjectExistAtIndexWithValue(tilesMediumMode, 5, "O") &&
      doesObjectExistAtIndexWithValue(tilesMediumMode, 8, "O")
    ) {
      dispatch(setGameOver());
      setWinner("Player O won the game!");
      openModalHandler();
    }
    if (
      doesObjectExistAtIndexWithValue(tilesMediumMode, 3, "X") &&
      doesObjectExistAtIndexWithValue(tilesMediumMode, 6, "X") &&
      doesObjectExistAtIndexWithValue(tilesMediumMode, 9, "X")
    ) {
      dispatch(setGameOver());
      setWinner("Player X won the game!");
      openModalHandler();
    }
    if (
      doesObjectExistAtIndexWithValue(tilesMediumMode, 3, "O") &&
      doesObjectExistAtIndexWithValue(tilesMediumMode, 6, "O") &&
      doesObjectExistAtIndexWithValue(tilesMediumMode, 9, "O")
    ) {
      dispatch(setGameOver());
      setWinner("Player O won the game!");
      openModalHandler();
    }
    if (
      doesObjectExistAtIndexWithValue(tilesMediumMode, 1, "X") &&
      doesObjectExistAtIndexWithValue(tilesMediumMode, 5, "X") &&
      doesObjectExistAtIndexWithValue(tilesMediumMode, 9, "X")
    ) {
      dispatch(setGameOver());
      setWinner("Player X won the game!");
      openModalHandler();
    }
    if (
      doesObjectExistAtIndexWithValue(tilesMediumMode, 1, "O") &&
      doesObjectExistAtIndexWithValue(tilesMediumMode, 5, "O") &&
      doesObjectExistAtIndexWithValue(tilesMediumMode, 9, "O")
    ) {
      dispatch(setGameOver());
      setWinner("Player O won the game!");
      openModalHandler();
    }
    if (
      doesObjectExistAtIndexWithValue(tilesMediumMode, 3, "X") &&
      doesObjectExistAtIndexWithValue(tilesMediumMode, 5, "X") &&
      doesObjectExistAtIndexWithValue(tilesMediumMode, 7, "X")
    ) {
      dispatch(setGameOver());
      setWinner("Player X won the game!");
      openModalHandler();
    }
    if (
      doesObjectExistAtIndexWithValue(tilesMediumMode, 3, "O") &&
      doesObjectExistAtIndexWithValue(tilesMediumMode, 5, "O") &&
      doesObjectExistAtIndexWithValue(tilesMediumMode, 7, "O")
    ) {
      dispatch(setGameOver());
      setWinner("Player O won the game!");
      openModalHandler();
    }
  }, [tilesMediumMode]);

  useEffect(() => {
    const over = localStorage.getItem("gameOver");
    if (tilesMediumMode?.length === 9 && !over) {
      setTimeout(() => {
        dispatch(setGameOver());
        // setWinner("It's a Draw !");
        setWinType("0");
        openModalHandler();
      }, 600);
    }
  }, [tilesMediumMode, gameOver]);

  useEffect(() => {
    const over = localStorage.getItem("gameOver");
    if (computer && tilesMediumMode?.length !== 9 && !over) {
      setTimeout(() => {
        setSoundX(false);
        setSoundO(true);
        dispatch(setTilesMediumModeComputer());
        dispatch(toggleTurn());
      }, 600);
    }
  }, [computer, gameOver]);

  const renderTiles = () => {
    const tilesArray = new Array(9).fill(null);

    return tilesArray.map((_, index) => (
      <div
        key={index}
        className={`${
          index + 1 === 1
            ? classes.tile_one
            : index + 1 === 2
            ? classes.tile_two
            : index + 1 === 3
            ? classes.tile_three
            : index + 1 === 4
            ? classes.tile_four
            : index + 1 === 5
            ? classes.tile_five
            : index + 1 === 6
            ? classes.tile_six
            : index + 1 === 7
            ? classes.tile_seven
            : index + 1 === 8
            ? classes.tile_eight
            : classes.tile_nine
        }`}
        onClick={() => handleTileClick({ index: index + 1 })}
      >
        {Array.isArray(tilesMediumMode)
          ? tilesMediumMode.map((tile) => {
              console.log(tile, "t");
              return (
                <h1
                  style={
                    tile?.value === "X"
                      ? { color: "#e8d615", fontSize: "7rem" }
                      : {
                          color: "white",
                          fontSize: "7rem",
                        }
                  }
                >
                  {tile?.index === index + 1 ? tile?.value : null}
                </h1>
              );
            })
          : null}
      </div>
    ));
  };

  return (
    <div className={classes.container}>
      <div className={classes.sub_container}>
      <div className={classes.game_title}>
          <div className={classes.title_image}>
            <img
              src="/assets/images/noughtsAndCrosses.png"
              className={classes.image}
              alt="....."
            />
          </div>
        </div>

        {soundX ? <Audio /> : soundO ? <AudioForComputer /> : null}

        <div className={classes.player_turn_indicator}>
          <h1>
            {player1
              ? "Player X's Turn "
              : computer
              ? "Computer O's Turn"
              : null}
          </h1>
        </div>

        <div className={classes.x_img_container}>
          <img
            src="/assets/images/bg_x.png"
            alt="X mark"
            className={classes.x_img}
          />
        </div>
        <div className={classes.circle_img_container}>
          <img
            src="/assets/images/bg_circle.png"
            alt="Circle mark"
            className={classes.circle_img}
          />
        </div>

        <div className={classes.grid_container}>
          <img
            src="/assets/images/background-grid.jpg"
            className={classes.bg_img}
          />
          <div className={classes.grid}>{renderTiles()}</div>
        </div>
      </div>
      {modal && (
        <GameOverModal
          winner={winner}
          winType={winType}
          closeModalHandler={closeModalHandler}
          path="/single-player/game-mode-medium"
        />
      )}
    </div>
  );
};

export default SinglePlayerMediumMode;
