import React, { useEffect, useState } from "react";
import classes from "./GameOverModal.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { reset2 } from "../utils/gameFunctions";
import axios from "axios";
import { saveScoreApi } from "../api/api";
import { toast } from "react-toastify";
import { setPointsSaved, setUserPoints } from "../slices/pointsSlice";
import Loading from "./Loading";

const GameOverModal = ({ winner, winType, closeModalHandler }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { modal } = useSelector((state) => state.modalSlice);
  const { points, pointsSaved ,userPoints} = useSelector((state) => state.pointsSlice);
  const { user } = useSelector((state) => state.usersSlice);
  const [loading, setLoading] = useState(true);

  const navigateHandler = (path) => {
    closeModalHandler();
    reset2(dispatch);
    navigate(path);
  };
  const closeModalAndResetState = () => {
    closeModalHandler();
    reset2(dispatch);
  };

  console.log(winner, "winner");

  const saveScore = async () => {
    try {
      setLoading(true);
      const data = {
        score: points,
        status: winner?.includes("X") && "win",
      };
      const res = await axios.post(saveScoreApi, data, {
        headers: {
          Authorization: `Bearer ${user?.response}`,
        },
      });
      dispatch(setPointsSaved());
      dispatch(setUserPoints(res?.data?.result));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(
        error?.response?.data?.message ||
          error?.data?.message ||
          error?.message ||
          error
      );
    }
  };

  const saveScore2 = async () => {
    try {
      setLoading(true);
      const data = {
        score: points,
        status: winner?.includes("O") && "lose",
      };
      const res = await axios.post(saveScoreApi, data, {
        headers: {
          Authorization: `Bearer ${user?.response}`,
        },
      });
      dispatch(setPointsSaved());
      dispatch(setUserPoints(res?.data?.result));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(
        error?.response?.data?.message ||
          error?.data?.message ||
          error?.message ||
          error
      );
    }
  };

  useEffect(() => {
    if (modal && !pointsSaved) {
      if (winner?.includes("X")) {
        saveScore();
      }
      if (winner?.includes("O")) {
        saveScore2();
      } else {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  }, [modal, pointsSaved, saveScore, saveScore2]);

  return (
    <div className={classes.container}>
      <div className={classes.sub_container}>
        {loading ? (
          <Loading />
        ) : (
          <>
            <div className={classes.modal_img_container}>
              {winType == "0" ? (
                <img
                  className={classes.modal_img}
                  src="/assets/images/game_over.png"
                  alt="result"
                />
              ) : (
                <img
                  className={classes.modal_img}
                  src="/assets/images/happy.png"
                  alt="result"
                />
              )}
            </div>
            <div className={classes.modal_description}>
              {/* <h1>{winType == "0" ? "Draw!" : "Congratulations!"}</h1> */}
              <h1>
                {winType == "0" || !winner ? "Draw!" : "Congratulations!"}
              </h1>
              <p>{winType != "0" && winner}</p>
              <div className={classes.points_container}>
            <img
              src="/assets/images/star.png"
              alt=""
              className={classes.point_image}
            />
            {userPoints}
          </div>
            </div>
            <div className={classes.buttons_container}>
              <button
                type="button"
                className={classes.play_btn}
                onClick={() => closeModalAndResetState()}
              >
                Play Again!
              </button>
              <button
                type="button"
                className={classes.back_btn}
                onClick={() => navigateHandler("/")}
              >
                Go Back!
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default GameOverModal;
