import { configureStore } from "@reduxjs/toolkit";
import playerSlice from "./slices/playerSlice";
import modalSlice from "./slices/modalSlice";
import tilesSliceMediumMode from "./slices/tilesSliceMedium";
import tilesSliceHardMode from "./slices/tilesSliceHard";
import gameOverSlice from "./slices/gameOverSlice";
import tilesSliceEasyMode from "./slices/tilesSliceEasy";
import tilesSlice from "./slices/tilesSlice";
import tiles5Slice from "./slices/tiles5Slice";
import tiles7Slice from "./slices/tiles7Slice";
import usersSlice from "./slices/usersSlice";
import pointsSlice from "./slices/pointsSlice";

const store = configureStore({
  reducer: {
    tilesSlice:tilesSlice.reducer,
    tilesSliceEasyMode: tilesSliceEasyMode.reducer,
    tilesSliceMediumMode: tilesSliceMediumMode.reducer,
    playerSlice: playerSlice.reducer,
    modalSlice: modalSlice.reducer,
    tilesSliceHardMode: tilesSliceHardMode.reducer,
    gameOverSlice: gameOverSlice.reducer,
    tiles5Slice:tiles5Slice.reducer,
    tiles7Slice:tiles7Slice.reducer,
    usersSlice:usersSlice.reducer,
    pointsSlice:pointsSlice.reducer
  },
});

export default store;
