import React from "react";

const Audio = () => {
  return (
    <audio id="backgroundMusic" autoPlay>
        <source src="/assets/sounds/x-sound.wav" type="audio/wav" />
    </audio>
  );
};

export default Audio;
