import { createSlice } from "@reduxjs/toolkit";

const initialState = localStorage.getItem("tilesEasyMode")
  ? JSON.parse(localStorage.getItem("tilesEasyMode"))
  : {
      tilesEasyMode: [],
    };

const tilesSliceEasyMode = createSlice({
  name: "tilesSliceEasyMode",
  initialState: initialState,
  reducers: {
    setTilesEasyMode: (state, action) => {
      state.tilesEasyMode = [...state.tilesEasyMode, action.payload];
      localStorage.setItem("tilesEasyMode", JSON.stringify(state));
      return state;
    },
    setTilesEasyModeComputer: (state, action) => {
      const randomTile = () => {
        const getRandomNumber = () => {
          return Math.floor(Math.random() * 9) + 1;
        };

        let number = getRandomNumber();

        function doesObjectExistAtIndexWithValue(
          array,
          indexToCheck,
          valueToCheck
        ) {
          const foundObject = array.find((obj) => obj.index === indexToCheck);

          return foundObject && foundObject.value === valueToCheck;
        }

        if (
          doesObjectExistAtIndexWithValue(state.tilesEasyMode, 1, "X") &&
          doesObjectExistAtIndexWithValue(state.tilesEasyMode, 2, "X") &&
          !doesObjectExistAtIndexWithValue(state.tilesEasyMode, 3, "O") &&
          !doesObjectExistAtIndexWithValue(state.tilesEasyMode, 3, "X")
        ) {
          state.tilesEasyMode = [...state.tilesEasyMode, { index: 3, value: "O" }];
          localStorage.setItem("tilesEasyMode", JSON.stringify(state));
          return state;
        } else if (
          doesObjectExistAtIndexWithValue(state.tilesEasyMode, 4, "X") &&
          doesObjectExistAtIndexWithValue(state.tilesEasyMode, 5, "X") &&
          !doesObjectExistAtIndexWithValue(state.tilesEasyMode, 6, "O") &&
          !doesObjectExistAtIndexWithValue(state.tilesEasyMode, 6, "X")
        ) {
          state.tilesEasyMode = [...state.tilesEasyMode, { index: 6, value: "O" }];
          localStorage.setItem("tilesEasyMode", JSON.stringify(state));
          return state;
        } else if (
          doesObjectExistAtIndexWithValue(state.tilesEasyMode, 7, "X") &&
          doesObjectExistAtIndexWithValue(state.tilesEasyMode, 8, "X") &&
          !doesObjectExistAtIndexWithValue(state.tilesEasyMode, 9, "O") &&
          !doesObjectExistAtIndexWithValue(state.tilesEasyMode, 9, "X")
        ) {
          state.tilesEasyMode = [...state.tilesEasyMode, { index: 9, value: "O" }];
          localStorage.setItem("tilesEasyMode", JSON.stringify(state));
          return state;
        } else if (
          doesObjectExistAtIndexWithValue(state.tilesEasyMode, 1, "X") &&
          doesObjectExistAtIndexWithValue(state.tilesEasyMode, 4, "X") &&
          !doesObjectExistAtIndexWithValue(state.tilesEasyMode, 7, "O") &&
          !doesObjectExistAtIndexWithValue(state.tilesEasyMode, 7, "X")
        ) {
          state.tilesEasyMode = [...state.tilesEasyMode, { index: 7, value: "O" }];
          localStorage.setItem("tilesEasyMode", JSON.stringify(state));
          return state;
        } else if (
          doesObjectExistAtIndexWithValue(state.tilesEasyMode, 2, "X") &&
          doesObjectExistAtIndexWithValue(state.tilesEasyMode, 5, "X") &&
          !doesObjectExistAtIndexWithValue(state.tilesEasyMode, 8, "O") &&
          !doesObjectExistAtIndexWithValue(state.tilesEasyMode, 8, "X")
        ) {
          state.tilesEasyMode = [...state.tilesEasyMode, { index: 8, value: "O" }];
          localStorage.setItem("tilesEasyMode", JSON.stringify(state));
          return state;
        } else if (
          doesObjectExistAtIndexWithValue(state.tilesEasyMode, 3, "X") &&
          doesObjectExistAtIndexWithValue(state.tilesEasyMode, 6, "X") &&
          !doesObjectExistAtIndexWithValue(state.tilesEasyMode, 9, "O") &&
          !doesObjectExistAtIndexWithValue(state.tilesEasyMode, 9, "X")
        ) {
          state.tilesEasyMode = [...state.tilesEasyMode, { index: 9, value: "O" }];
          localStorage.setItem("tilesEasyMode", JSON.stringify(state));
          return state;
        } else if (
          doesObjectExistAtIndexWithValue(state.tilesEasyMode, 1, "X") &&
          doesObjectExistAtIndexWithValue(state.tilesEasyMode, 5, "X") &&
          !doesObjectExistAtIndexWithValue(state.tilesEasyMode, 9, "O") &&
          !doesObjectExistAtIndexWithValue(state.tilesEasyMode, 9, "X")
        ) {
          state.tilesEasyMode = [
            ...state.tilesEasyMode,
            { index: 9, value: "O" },
          ];
          localStorage.setItem("tilesEasyMode", JSON.stringify(state));
          return state;
        } else if (
          doesObjectExistAtIndexWithValue(state.tilesEasyMode, 3, "X") &&
          doesObjectExistAtIndexWithValue(state.tilesEasyMode, 5, "X") &&
          !doesObjectExistAtIndexWithValue(state.tilesEasyMode, 7, "O") &&
          !doesObjectExistAtIndexWithValue(state.tilesEasyMode, 7, "X")
        ) {
          state.tilesEasyMode = [
            ...state.tilesEasyMode,
            { index: 7, value: "O" },
          ];
          localStorage.setItem("tilesEasyMode", JSON.stringify(state));
          return state;
        } else if (
          doesObjectExistAtIndexWithValue(state.tilesEasyMode, 1, "X") &&
          doesObjectExistAtIndexWithValue(state.tilesEasyMode, 3, "X") &&
          !doesObjectExistAtIndexWithValue(state.tilesEasyMode, 2, "O") &&
          !doesObjectExistAtIndexWithValue(state.tilesEasyMode, 2, "X")
        ) {
          state.tilesEasyMode = [
            ...state.tilesEasyMode,
            { index: 2, value: "O" },
          ];
          localStorage.setItem("tilesEasyMode", JSON.stringify(state));
          return state;
        } else if (
          doesObjectExistAtIndexWithValue(state.tilesEasyMode, 4, "X") &&
          doesObjectExistAtIndexWithValue(state.tilesEasyMode, 6, "X") &&
          !doesObjectExistAtIndexWithValue(state.tilesEasyMode, 5, "O") &&
          !doesObjectExistAtIndexWithValue(state.tilesEasyMode, 5, "X")
        ) {
          state.tilesEasyMode = [
            ...state.tilesEasyMode,
            { index: 5, value: "O" },
          ];
          localStorage.setItem("tilesEasyMode", JSON.stringify(state));
          return state;
        } else if (
          doesObjectExistAtIndexWithValue(state.tilesEasyMode, 7, "X") &&
          doesObjectExistAtIndexWithValue(state.tilesEasyMode, 9, "X") &&
          !doesObjectExistAtIndexWithValue(state.tilesEasyMode, 8, "O") &&
          !doesObjectExistAtIndexWithValue(state.tilesEasyMode, 8, "X")
        ) {
          state.tilesEasyMode = [
            ...state.tilesEasyMode,
            { index: 8, value: "O" },
          ];
          localStorage.setItem("tilesEasyMode", JSON.stringify(state));
          return state;
        } 

        else if (
          doesObjectExistAtIndexWithValue(state.tilesEasyMode, 6, "X") &&
          doesObjectExistAtIndexWithValue(state.tilesEasyMode, 5, "X") &&
          !doesObjectExistAtIndexWithValue(state.tilesEasyMode, 4, "O") &&
          !doesObjectExistAtIndexWithValue(state.tilesEasyMode, 4, "X")
        ) {
          state.tilesEasyMode = [
            ...state.tilesEasyMode,
            { index: 4, value: "O" },
          ];
          localStorage.setItem("tilesEasyMode", JSON.stringify(state));
          return state;
        } 

        else if (
          doesObjectExistAtIndexWithValue(state.tilesEasyMode, 9, "X") &&
          doesObjectExistAtIndexWithValue(state.tilesEasyMode, 8, "X") &&
          !doesObjectExistAtIndexWithValue(state.tilesEasyMode, 7, "O") &&
          !doesObjectExistAtIndexWithValue(state.tilesEasyMode, 7, "X")
        ) {
          state.tilesEasyMode = [
            ...state.tilesEasyMode,
            { index: 7, value: "O" },
          ];
          localStorage.setItem("tilesEasyMode", JSON.stringify(state));
          return state;
        } 

        else if (
          doesObjectExistAtIndexWithValue(state.tilesEasyMode, 7, "X") &&
          doesObjectExistAtIndexWithValue(state.tilesEasyMode, 4, "X") &&
          !doesObjectExistAtIndexWithValue(state.tilesEasyMode, 1, "O") &&
          !doesObjectExistAtIndexWithValue(state.tilesEasyMode, 1, "X")
        ) {
          state.tilesEasyMode = [
            ...state.tilesEasyMode,
            { index: 1, value: "O" },
          ];
          localStorage.setItem("tilesEasyMode", JSON.stringify(state));
          return state;
        } 
        else if (
          doesObjectExistAtIndexWithValue(state.tilesEasyMode, 8, "X") &&
          doesObjectExistAtIndexWithValue(state.tilesEasyMode, 5, "X") &&
          !doesObjectExistAtIndexWithValue(state.tilesEasyMode, 2, "O") &&
          !doesObjectExistAtIndexWithValue(state.tilesEasyMode, 2, "X")
        ) {
          state.tilesEasyMode = [
            ...state.tilesEasyMode,
            { index: 2, value: "O" },
          ];
          localStorage.setItem("tilesEasyMode", JSON.stringify(state));
          return state;
        } 
        
        else if (
          !doesObjectExistAtIndexWithValue(state.tilesEasyMode, number, "X") &&
          !doesObjectExistAtIndexWithValue(state.tilesEasyMode, number, "O")
        ) {
          state.tilesEasyMode = [...state.tilesEasyMode, { index: number, value: "O" }];
          localStorage.setItem("tilesEasyMode", JSON.stringify(state));
          return state;
        } else if (state.tilesEasyMode.length == 9) {
          return;
        } else {
          return randomTile();
        }
      };
      randomTile();
    },
    resetTilesEasyMode: (state, action) => {
      state.tilesEasyMode = [];
      localStorage.removeItem("tilesEasyMode");
      return state;
    },
  },
});

export const { setTilesEasyMode, resetTilesEasyMode, setTilesEasyModeComputer } =
  tilesSliceEasyMode.actions;
export default tilesSliceEasyMode;
