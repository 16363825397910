import React from "react";
import { Link, RouterProvider, createBrowserRouter } from "react-router-dom";
import MultiPlayerGrid3Game from "../pages/MultiPlayerGrid3Game";
import HomePage from "../pages/HomePage";
import SinglePlayerEasyMode from "../pages/SinglePlayerEasyMode";
import SinglePlayerMediumMode from "../pages/SinglePlayerMediumMode";
import LevelsPage from "../pages/LevelsPage";
import SinglePlayerHardMode from "../pages/SinglePlayerHardMode";
import SinglePlayerGrid5Game from "../pages/SinglePlayerGrid5Game";
import SinglePlayerGrid7Game from "../pages/SinglePlayerGrid7Game";
import MultiplayerLevelsPage from "../pages/MultiplayerLevelsPage";
import MultiPlayerGrid5Game from "../pages/MultiPlayerGrid5Game";
import MultiPlayerGrid7Game from "../pages/MultiPlayerGrid7Game";
import LoginPage from "../pages/LoginPage";
import AccountPage from "../pages/AccountPage";
import RegisterPage from "../pages/RegisterPage";
import Auth from "../components/Auth";

const Routing = () => {
  const router = createBrowserRouter([
    {
      path: "/login",
      element: <LoginPage />,
    },
    {
      path: "/register",
      element: <RegisterPage />,
    },
    {
      path: "/",
      element: (
        <Auth>
          <HomePage />
        </Auth>
      ),
    },
    {
      path: "/single-player/levels",
      element: (
        <Auth>
          <LevelsPage />
        </Auth>
      ),
    },
    {
      path: "/multi-player/levels",
      element: (
        <Auth>
          <MultiplayerLevelsPage />
        </Auth>
      ),
    },
    {
      path: "/multi-player/game-mode-3*3",
      element: (
        <Auth>
          <MultiPlayerGrid3Game />
        </Auth>
      ),
    },
    {
      path: "/multi-player/game-mode-5*5",
      element: (
        <Auth>
          <MultiPlayerGrid5Game />
        </Auth>
      ),
    },
    {
      path: "/multi-player/game-mode-7*7",
      element: (
        <Auth>
          <MultiPlayerGrid7Game />
        </Auth>
      ),
    },
    {
      path: "/single-player/game-mode-easy",
      element: (
        <Auth>
          <SinglePlayerEasyMode />
        </Auth>
      ),
    },
    {
      path: "/single-player/game-mode-medium",
      element: (
        <Auth>
          <SinglePlayerMediumMode />
        </Auth>
      ),
    },
    {
      path: "/single-player/game-mode-hard",
      element: (
        <Auth>
          <SinglePlayerHardMode />
        </Auth>
      ),
    },
    {
      path: "/single-player/game-mode-5*5",
      element: (
        <Auth>
          <SinglePlayerGrid5Game />
        </Auth>
      ),
    },
    {
      path: "/single-player/game-mode-7*7",
      element: (
        <Auth>
          <SinglePlayerGrid7Game />
        </Auth>
      ),
    },
    {
      path: "/account",
      element: (
        <Auth>
          <AccountPage />
        </Auth>
      ),
    },
  ]);
  return <RouterProvider router={router} />;
};

export default Routing;
