import React, { useEffect, useState } from "react";
import classes from "./LevelsPage.module.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { reset } from "../utils/gameFunctions";
import { toast } from "react-toastify";
import axios from "axios";
import Navbar from "../components/Navbar";
import { levelsApi } from "../api/api";
import { resetPoints, setPoints } from "../slices/pointsSlice";
import Loading from "../components/Loading";

const LevelsPage = () => {
  const { user } = useSelector((state) => state.usersSlice);
  const [levels, setLevels] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleNavigate = (object) => {
    reset(dispatch);
    dispatch(resetPoints());
    dispatch(setPoints(object?.points));
    navigate(object?.path);
  };
  const fetchLevelsWithPointsFromBackend = async () => {
    try {
      setLoading(true);
      const token = user?.response;
      const res = await axios.get(levelsApi, null, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setLevels(res?.data?.result);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(
        error?.response?.data?.message ||
          error?.data?.message ||
          error?.message ||
          error?.data
      );
    }
  };
  useEffect(() => {
    if (!user) {
      navigate("/login");
    } else {
      fetchLevelsWithPointsFromBackend();
    }
  }, []);
  return (
    <>
      <Navbar />
      <div className={classes.container}>
        <div className={classes.game_title}>
          <div className={classes.title_image}>
            <img
              src="/assets/images/noughtsAndCrosses.png"
              className={classes.image}
              alt="....."
            />
          </div>
        </div>
        <div className={classes.game_mode_container}>
          {loading ? (
            <Loading />
          ) : (
            <>
              {levels?.map((data, index) => {
                return (
                  <div className={classes.levels_container} key={index}>
                    <button
                      key={index}
                      className={classes.easy_btn}
                      onClick={() =>
                        handleNavigate({
                          path: `/single-player/${data?.routes}`,
                          points: data?.points,
                        })
                      }
                    >
                      {data?.level}
                    </button>
                    <div className={classes.points_container}>
                      <img
                        src="/assets/images/star.png"
                        alt=""
                        className={classes.point_image}
                      />
                      {data?.points}
                    </div>
                  </div>
                );
              })}
              <div className={classes.easy_btn}>
                You will gain or lose points based on the points specified
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default LevelsPage;
